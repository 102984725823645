import { tabMapping } from '../components/page/dashboard/Tabs'

export const QUERY_VALUE_DELIMITER = '&'

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search)
}

export const getTab = () => {
  const queryParams = getQueryParams()
  return queryParams.get('tab')
}

export const getQueryParam = (name, split) => {
  const queryParams = getQueryParams()
  let data = queryParams.get(name)
  if (split && data) {
    return data.split(QUERY_VALUE_DELIMITER)
  }
  return data
}

export const setQueryParam = (key, value) => {
  const queryParams = getQueryParams()
  queryParams.set(key, value)
  window.history.replaceState(null, null, '?' + queryParams.toString())
}

export const removeQueryParam = (key) => {
  const queryParams = getQueryParams()
  queryParams.delete(key)
  window.history.replaceState(null, null, '?' + queryParams.toString())
}

export const removeAllQueryParams = () => {
  window.history.replaceState(null, null, window.location.pathname)
}

export const findDashboardTab = (tabValue) => {
  return Object.keys(tabMapping).find((key) => tabMapping[key] === tabValue)
}

import React, { useState, useEffect } from 'react'
import { usePaymentActions } from '../../../../_actions'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Card, Row, Button, Col } from 'react-bootstrap'
import 'react-loading-skeleton/dist/skeleton.css'

const ProductChoiceTileBody = ({ user }) => {
  const [products, setProducts] = useState({})
  const { getProducts, getSession } = usePaymentActions()
  const [loading, setLoading] = useState(true)
  const accountActiveTo = new Date(user.account_active_to)
  const today = new Date()
  const oneDay = 1000 * 60 * 60 * 24 // milliseconds in one day
  const daysLeft = Math.round((accountActiveTo - today) / oneDay)

  useEffect(() => {
    getProducts()
      .then((resp) => {
        setProducts({
          sixMonthsProduct: resp.find((item) => item.slug === 'basic-183'),
          oneYearProduct: resp.find((item) => item.slug === 'basic-365'),
        })
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleCheckout = (priceId) => {
    getSession({ id: priceId })
      .then((resp) => {
        window.location.href = resp.url
      })
      .catch((err) => {
        // TODO: handle error
        console.error(err)
      })
  }

  const renderPlanCard = (product) => {
    const planDays = product.slug === 'basic-365' ? 365 : 183
    const disablePlan = daysLeft + planDays > 456
    return (
      <div className="card card-shadow border-0 mb-4">
        <div className="card-body">
          <div>
            <h5 className="font-weight-medium mt-2 mb-2 text-center">
              {product.slug === 'basic-365' ? 'Roczny Plan' : 'Półroczny Plan'}
            </h5>
          </div>
          <div className="row">
            <div className="text-center">
              <div className="">
                <span className="text-dark display-3">{product.price / 100}</span>
                <sup>PLN</sup>
                <h5 className="font-weight-light mt-3">{product.name}</h5>

                <button
                  className={`payment-btn eg-btn btn--md mt-2 mb-2 ${!disablePlan ? 'btn--primary' : ''}`}
                  onClick={(e) => {
                    if (!disablePlan) {
                      e.preventDefault()
                      e.target.disabled = true
                      handleCheckout(product.price_id)
                    }
                  }}
                  disabled={disablePlan}
                >
                  Przjedź do płatności
                </button>
                {disablePlan && <p className="account-info-text">Możesz mieć wykupioncyh maksymalnie 456 dni.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderSkeletonPlanCard = () => {
    return (
      <div className="card card-shadow border-0 mb-4">
        <div className="card-body p-4">
          <div>
            <h5 className="font-weight-medium mb-0 text-center">
              <Skeleton />
            </h5>
          </div>
          <div className="row">
            <div className="text-center">
              <div className="my-3">
                <span className="text-dark display-3">
                  <Skeleton />
                </span>

                <h5 className="font-weight-light">
                  <Skeleton />
                </h5>

                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="pricing6 w-100 py-5 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className="mb-3">Wybierz jeden z poniższych pakietów!</h3>
            <p className="para subtitle">Po wybraniu pakietu zostaniesz przeniesiony do serwisu płatnościowego</p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-6">
            {loading ? renderSkeletonPlanCard() : renderPlanCard(products.sixMonthsProduct)}
          </div>
          <div className="col-md-6">{loading ? renderSkeletonPlanCard() : renderPlanCard(products.oneYearProduct)}</div>
        </div>
      </div>
    </div>
  )
}

export default ProductChoiceTileBody

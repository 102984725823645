import React, { createContext, useContext, useState, useEffect } from 'react'
import { setQueryParam, getTab } from '../_helpers'
import { tabMapping } from '../components/page/dashboard/Tabs'
import { URL_PATHS } from '../_constants'
import { useLocation } from 'react-router-dom'

const DashboardTabContext = createContext()

export const useDashboardTab = () => useContext(DashboardTabContext)

export const DashboardTabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(
    getTab() ? Object.keys(tabMapping).find((key) => tabMapping[key] === getTab()) : 'tab01'
  )
  const location = useLocation()
  useEffect(() => {
    const tabName = tabMapping[activeTab]
    if (location.pathname === URL_PATHS.DASHBOARD) {
      if (tabName) {
        setQueryParam('tab', tabName)
      } else {
        setQueryParam('tab', tabMapping.tab01)
        setActiveTab('tab01')
      }
    }
  }, [activeTab])

  return <DashboardTabContext.Provider value={{ activeTab, setActiveTab }}>{children}</DashboardTabContext.Provider>
}

import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import FuchaWrap from './FuchaWrap'

const FuchaListing = () => {
  return (
    <>
      <Breadcrumb pageName="Aktywne Fuchy" pageTitle="Przeglądaj fuchy w całej Polsce!" />
      <FuchaWrap />
      {/* <AboutUsCounter /> */}
    </>
  )
}

export default FuchaListing

import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import ErrorWrapper from './ErrorWrapper'
function ErrorPage() {
  return (
    <>
      <Breadcrumb pageName="404" pageTitle="Ups, nie mogliśmy znaleźć tej strony.." />
      <ErrorWrapper />
    </>
  )
}

export default ErrorPage

import React from 'react'
import ConfirmModal from '../../../../common/ConfirmModal'
import AccountIcon from '../../../../../assets/images/icons/account.svg'
import { fixedValue } from './utils'
import Skeleton from 'react-loading-skeleton'

const ReviewItem = ({ label, value, loading }) => (
  <div className="rating-box">
    <div className="text-part">
      {loading ? (
        <p>
          <Skeleton width={100} />
        </p>
      ) : (
        <p>{label}</p>
      )}
    </div>
    <div className="stars-part">
      <i className="bi bi-star-fill"></i>
      {loading ? (
        <p>
          <Skeleton width={50} />
        </p>
      ) : (
        <p>{value}</p>
      )}
    </div>
  </div>
)

const ContractorReviewsSummaryModal = ({
  acceptedBid = null,
  bid = null,
  contractor_profile = null,
  contractorReviewsSummary,
  handleClose,
  loading,
  showModal,
}) => {
  const contractor = bid?.contractor || acceptedBid?.contractor || contractor_profile || {}
  const contractorId = bid?.id || acceptedBid?.id || ''
  const contractorImage = contractor.image || contractor_profile?.profile_image_thumbnail || AccountIcon
  const contractorName = contractor.name || ''

  return (
    <ConfirmModal
      header="Podsumowanie wszystkich recenzji tego fachowca"
      showModal={showModal}
      handleClose={handleClose}
      size="lg"
      className="contractor-review-modal contractor-summary-modal"
      hasFooter={false}
    >
      <img src={process.env.PUBLIC_URL + '/images/bg/client-bg.png'} className="review-bg" alt="" />
      <div className="row align-items-center flex-column review-container">
        <div className="col-lg-12 w-100">
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="image-container">
              {loading ? (
                <Skeleton circle width={110} height={110} />
              ) : (
                <img
                  id={`contractor-image-${contractorId}`}
                  src={contractorImage}
                  alt="Admin"
                  className="rounded-circle"
                  width="110"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-12 w-100">
          <div className="d-flex flex-column align-items-center h-100 w-100 contractor-name-box">
            {loading ? (
              <p>
                <Skeleton width={100} />
              </p>
            ) : (
              <p>{contractorName}</p>
            )}
          </div>
        </div>

        <div className="col-lg-12 w-100" style={{ paddingBottom: '20px' }}>
          <div className="d-flex flex-column align-items-center h-100" style={{ margin: 'auto' }}>
            <ReviewItem loading={loading} label="Liczba recenzji" value={contractorReviewsSummary.total_reviews} />
            <ReviewItem
              loading={loading}
              label="Ogólna ocena"
              value={fixedValue(contractorReviewsSummary.average_score, 2)}
            />
            <p className="middle-text">Średna z poszczególnych typów recenzji:</p>
            <ReviewItem
              loading={loading}
              label="Koszt wykonania"
              value={fixedValue(contractorReviewsSummary.price, 2)}
            />
            <ReviewItem
              loading={loading}
              label="Czas wykonania"
              value={fixedValue(contractorReviewsSummary.completion_time, 2)}
            />
            <ReviewItem
              loading={loading}
              label="Jakość wykonania"
              value={fixedValue(contractorReviewsSummary.quality, 2)}
            />
            <ReviewItem
              loading={loading}
              label="Kontakt z fachowcem"
              value={fixedValue(contractorReviewsSummary.contact, 2)}
            />
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
}

export default ContractorReviewsSummaryModal

import React from 'react'
import SimpleBox from '../../common/SimpleBox'
import { MAX_NUMBER_OF_ACTIVE_BIDS } from '../../../_constants/fucha'

const WhyChooseUsContractor = () => {
  return (
    <>
      <div className="choose-us-section pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-bottom" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>Dlaczego warto wybrać mamfuche.pl?</h2>
                <p className="para mb-0">
                  <b>mamfuche.pl</b> to nowoczesna platforma stworzona z myślą o fachowcach, którzy szukają fuch do
                  wykonania. Dzięki intuicyjnej obsłudze, możesz szybko i wygodnie przeglądać i porównywać fuchy w całej
                  Polsce. Aby maksymalnie ułatwić współpracę z właścicielami fuch, oferujemy funkcję czatu, która
                  umożliwia szybki i bezpośredni kontakt. Wystarczy jedna opłata, by zyskać pełen dostęp do platformy.
                  Dołącz do nas i zacznij zarabiać już dziś!
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <SimpleBox
              title="Dostęp do fuch w całej Polsce"
              text="Możliwość przeglądania fuch z różnych regionów, co daje większe szanse na znalezienie idealnych zleceń."
              src={process.env.PUBLIC_URL + '/images/icons/poland.svg'}
              icon
              number="01"
            />
            <SimpleBox
              title="Więcej szans na zlecenia"
              text={`Możesz wysyłać wyceny nawet do ${MAX_NUMBER_OF_ACTIVE_BIDS} fuch jednocześnie i składać aż do 2 wycen na każdą fuchę!`}
              src={process.env.PUBLIC_URL + '/images/icons/multiple.svg'}
              icon
              number="02"
            />
            <SimpleBox
              title="Żadnych ukrytych kosztów"
              text="Wszystkie opłaty są jasno określone, dzięki czemu nie musisz martwić się o dodatkowe koszty."
              src={process.env.PUBLIC_URL + '/images/icons/pig-bank.svg'}
              icon
              number="03"
            />
            <SimpleBox
              title="Opcja czatu z klientami"
              text="Bezpośrednia komunikacja z klientami poprzez wbudowany czat, ułatwiający negocjacje i szybką wymianę informacji."
              src={process.env.PUBLIC_URL + '/images/icons/chat.svg'}
              icon
              number="04"
            />
            <SimpleBox
              title="Profil fachowca"
              text="Stwórz profesjonalny profil, który pomoże Ci wyróżnić się na tle konkurencji i zdobyć zaufanie klientów."
              src={process.env.PUBLIC_URL + '/images/icons/contractor-profile.svg'}
              icon
              number="05"
            />
            <SimpleBox
              title="Pełen dostęp"
              text="Korzystaj z pełni możliwości i ciesz się wszystkimi funkcjami, które oferuje nasza platforma!"
              src={process.env.PUBLIC_URL + '/images/icons/access.svg'}
              icon
              number="06"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyChooseUsContractor

import React, { useState } from 'react'
import { useApi } from '../../../../../_helpers'
import axios from 'axios'
// Import React FilePond
import pl_PL from 'filepond/locale/pl-pl.js'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { MAX_IMAGE_SIZE_MB, MAX_GALLERY_IMAGES, API_URLS, DYNAMIC_API_URLS } from '../../../../../_constants'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond/dist/filepond.min.css'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const PageFive = () => {
  const [files, setFiles] = useState([])
  const { api } = useApi()

  return (
    <>
      <p>Dodaj zdjęcia z wykonanych prac</p>
      <div className="row upload-container">
        <FilePond
          {...pl_PL}
          files={files}
          allowMultiple={true}
          onupdatefiles={setFiles}
          maxFileSize={`${MAX_IMAGE_SIZE_MB}MB`}
          labelMaxFileSizeExceeded={`Maksymalny rozmiar pliku to ${MAX_IMAGE_SIZE_MB}MB`}
          acceptedFileTypes={['image/jpeg', 'image/png']}
          labelIdle={`Ilość zdjęć ${files.length}/${MAX_GALLERY_IMAGES}<br>Przeciągnij lub dodaj zdjęcia <span class="filepond--label-action">TUTAJ</span>`}
          maxFiles={6}
          name="file"
          allowImageEdit={true}
          storeAsFile={true}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
              const formData = new FormData()
              formData.append(fieldName, file, file.name)

              // Creating a CancelToken source
              const sourceToken = axios.CancelToken.source()

              api
                .post(API_URLS.CONTRACTOR_GALLERY, formData, {
                  onUploadProgress: (e) => {
                    progress(e.lengthComputable, e.loaded, e.total)
                  },
                  cancelToken: sourceToken.token,
                })
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    const uniqueFileId = response.data.id
                    load(uniqueFileId)
                  } else {
                    error('An error occurred')
                  }
                })
                .catch((err) => {
                  if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                  } else {
                    error(err.message || 'An error occurred')
                  }
                })

              // Return the abort method so FilePond can cancel the upload if needed
              return {
                abort: () => {
                  sourceToken.cancel('Operation canceled by the user.')
                  abort()
                },
              }
            },
            revert: (uniqueFileId, load, error) => {
              api
                .delete(DYNAMIC_API_URLS.ContractorGalleryDetail(uniqueFileId), {})
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    load()
                  } else {
                    error('An error occurred')
                  }
                })
                .catch((err) => {
                  error(err.message || 'An error occurred')
                })
            },
          }}
        />
      </div>
    </>
  )
}

export default PageFive

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DisabledFuchaCard = ({ loadingBids, scrollToOffer }) => {
  const renderSkeleton = () => {
    return (
      <div className="form-title">
        <>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <button className="eg-btn btn--primary p-2 m-3 join-btn" onClick={scrollToOffer}>
            Sprawdź Wyceny
          </button>
        </>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Niestety Twoja fucha nie jest już aktywna</h4>
          <span />
        </div>
        <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
          <div className="bid-form">
            {loadingBids ? (
              renderSkeleton()
            ) : (
              <div className="form-title">
                <p>
                  Aktualnie nie możesz już akceptować żadnych wycen. Jeżeli nadal potrzebujesz fachowca do tej fuchy
                  wystaw ją ponownie.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisabledFuchaCard

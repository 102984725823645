import React, { createContext, useContext, useState, useEffect } from 'react'
import { useUserActions } from '../_actions/user.actions'

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const { getUser, logout } = useUserActions()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  if (user && ['production', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT) && window.Brevo) {
    window.Brevo.push(function () {
      window.Brevo.identify({
        identifiers: {
          email_id: user.eamil,
        },
      })
    })
  }

  const refreshUser = async () => {
    setLoading(true)
    try {
      const userData = await getUser()
      setUser(userData)
    } catch (error) {
      console.error('Error refreshing user data:', error)
      logout()
    }
    setLoading(false)
  }

  useEffect(() => {
    refreshUser()
  }, [])

  return <UserContext.Provider value={{ user, setUser, refreshUser, loading }}>{children}</UserContext.Provider>
}

import { useApi } from '../_helpers'
import { API_URLS } from '../_constants'

export const useChatActions = () => {
  const api = useApi()

  return {
    getChatToken,
    getUnreadMessagesCount,
  }

  async function getChatToken() {
    const response = await api.get(API_URLS.CHAT_TOKEN)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getUnreadMessagesCount() {
    const response = await api.get(API_URLS.CHAT_UNREAD_MESSAGES_COUNT)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

import React from 'react'

function Preloader(props) {
  return (
    <>
      <div className={`${props.styles}`}>
        {props.text && <h2 style={{ marginBottom: 240 }}>{props.text}</h2>}
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  )
}

export default Preloader

// src/components/Alerts.js
import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import { useDashboardTab } from '../../context/DashboardTabContext'
import { useUserActions } from '../../_actions'
import Drawer from '@mui/material/Drawer'
import Badge from '@mui/material/Badge'
import { useAuth } from '../../context/AuthContext'
import { useNotificationsAndMessages } from '../../context/NotificationsAndMessagesContext'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PaymentIcon from '@mui/icons-material/Payment'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SearchIcon from '@mui/icons-material/Search'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import MenuIcon from '@mui/icons-material/Menu'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import GridViewIcon from '@mui/icons-material/GridView'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import { URL_PATHS } from '../../_constants'
import { history, scrollToTop, findDashboardTab } from '../../_helpers'
import { tabMapping } from '../page/dashboard/Tabs'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#f9395f',
      light: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Saira',
  },
})

const BottomNav = () => {
  const { logout } = useUserActions()
  const { unreadNotificationCount, unreadMessagesCount, loadingNotificationsAndMessages } =
    useNotificationsAndMessages()
  const { activeTab, setActiveTab } = useDashboardTab()
  const [navValue, setNavValue] = useState(0)
  const [open, setOpen] = useState(false)
  const { isAuth, auth } = useAuth()
  const isContractor = auth?.user?.is_contractor || false

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const getCurrentPathWithParams = () => {
    return window.location.pathname + window.location.search
  }

  const currentPathWithParams = getCurrentPathWithParams()

  const handleNavChange = (event, newValue) => {
    if (newValue === 'more') {
      setOpen(!open ? true : false)
    } else {
      setOpen(false)
      const params = new URLSearchParams(newValue.split('?')[1])
      const tabValue = params.get('tab')
      if (tabValue) {
        setActiveTab(findDashboardTab(params.get('tab')))
        history.push({ pathname: newValue.split('?')[0], search: `?tab=${tabValue}` })
      } else {
        setActiveTab(null)
        history.push(newValue)
      }
      scrollToTop()
    }
    setNavValue(newValue)
  }

  const handlePageChange = (path, tab) => {
    if (tab) {
      setActiveTab(findDashboardTab(tab))
    }
    scrollToTop()
    history.push(path)
  }

  useEffect(() => {
    const urlPath = window.location.pathname
    setNavValue(urlPath)
  }, [])

  const CommonDrawerListItems = () => (
    <>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentPathWithParams === URL_PATHS.HOME}
          onClick={() => handlePageChange(URL_PATHS.HOME)}
        >
          <ListItemIcon selected={currentPathWithParams === URL_PATHS.HOME}>
            <HomeOutlinedIcon selected />
          </ListItemIcon>
          <ListItemText primary={'Start'} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentPathWithParams === URL_PATHS.FUCHA_LISTING}
          onClick={() => handlePageChange(URL_PATHS.FUCHA_LISTING)}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={'Przeglądaj fuchy'} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentPathWithParams === URL_PATHS.ABOUT}
          onClick={() => handlePageChange(URL_PATHS.ABOUT)}
        >
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={'Kim jesteśmy'} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentPathWithParams === URL_PATHS.HOW_IT_WORKS}
          onClick={() => handlePageChange(URL_PATHS.HOW_IT_WORKS)}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={'Jak to Działa'} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={currentPathWithParams === URL_PATHS.CONTACT}
          onClick={() => handlePageChange(URL_PATHS.CONTACT)}
        >
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={'Kontakt'} />
        </ListItemButton>
      </ListItem>
    </>
  )

  const AnonymousDrawerList = () => (
    <Box sx={{ width: 250, paddingTop: 0 }} role="presentation" onClick={toggleDrawer(false)}>
      <div class="header-logo">
        <a href="/">
          <p class="logo-text">mamfuche.pl</p>
        </a>
      </div>
      <List sx={{ paddingTop: 0 }}>
        <CommonDrawerListItems />
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.LOGIN}
            onClick={() => handlePageChange(URL_PATHS.LOGIN)}
          >
            <ListItemIcon>
              <LoginOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Zaloguj'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.SIGNUP}
            onClick={() => handlePageChange(URL_PATHS.SIGNUP)}
          >
            <ListItemIcon>
              <HowToRegOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Rejestracja'} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <p className="drawer-divider-text">Dla Fachowców</p>
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.PRICES}
            onClick={() => handlePageChange(URL_PATHS.PRICES)}
          >
            <ListItemIcon>
              <PaymentsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Cennik'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.HOW_IT_WORKS_CONTRACTOR}
            onClick={() => handlePageChange(URL_PATHS.HOW_IT_WORKS_CONTRACTOR)}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Jak to Działa'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.LOGIN}
            onClick={() => handlePageChange(URL_PATHS.LOGIN)}
          >
            <ListItemIcon>
              <LoginOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Zaloguj'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={currentPathWithParams === URL_PATHS.SIGNUP_CONTRACTOR}
            onClick={() => handlePageChange(URL_PATHS.SIGNUP_CONTRACTOR)}
          >
            <ListItemIcon>
              <HowToRegOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Dołącz jako Fachowiec'} />
          </ListItemButton>
        </ListItem>

        <Divider />
      </List>
    </Box>
  )

  const UserDrawerList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div class="header-logo">
        <a href="/">
          <p class="logo-text">mamfuche.pl</p>
        </a>
      </div>
      <List sx={{ paddingTop: 0 }}>
        <CommonDrawerListItems />
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            selected={[URL_PATHS.DASHBOARD, URL_PATHS.DASHBOARD_SUMMARY].includes(currentPathWithParams)}
            onClick={() => handlePageChange(URL_PATHS.DASHBOARD, tabMapping.tab01)}
          >
            <ListItemIcon>
              <GridViewIcon />
            </ListItemIcon>
            <ListItemText primary={'Podsumowanie'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={`${URL_PATHS.DASHBOARD}?tab=konto` === currentPathWithParams}
            onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=konto`, tabMapping.tab03)}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary={'Moje Konto'} />
          </ListItemButton>
        </ListItem>
        {isContractor && (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=profil` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=profil`, tabMapping.tab11)}
            >
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={'Profil Fachowca'} />
            </ListItemButton>
          </ListItem>
        )}

        {isContractor ? (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=fuchy` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=fuchy`, tabMapping.tab12)}
            >
              <ListItemIcon>
                <HomeRepairServiceIcon />
              </ListItemIcon>
              <ListItemText primary={'Moje Fuchy'} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=moje-fuchy` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=moje-fuchy`, tabMapping.tab22)}
            >
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary={'Moje Fuchy'} />
            </ListItemButton>
          </ListItem>
        )}
        {!isContractor && (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=wystaw-fuche` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=wystaw-fuche`, tabMapping.tab21)}
            >
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={'Wystaw Fuchę'} />
            </ListItemButton>
          </ListItem>
        )}
        {isContractor && (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=platnosci` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=platnosci`, tabMapping.tab13)}
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary={'Płatności'} />
            </ListItemButton>
          </ListItem>
        )}
        {isContractor && (
          <ListItem disablePadding>
            <ListItemButton
              selected={`${URL_PATHS.DASHBOARD}?tab=ocen-fuche` === currentPathWithParams}
              onClick={() => handlePageChange(`${URL_PATHS.DASHBOARD}?tab=ocen-fuche`, tabMapping.tab14)}
            >
              <ListItemIcon>
                <StarBorderIcon />
              </ListItemIcon>
              <ListItemText primary={'Oceń Fuchę'} />
            </ListItemButton>
          </ListItem>
        )}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              scrollToTop()
              logout()
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Wyloguj się'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <ThemeProvider theme={theme}>
      <div className="d-lg-none d-block" style={{ height: '55px', width: '100%', backgroundColor: '#000' }}></div>
      <Paper
        className="bottom-nav-container"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          paddingTop: '5px',
        }}
        elevation={3}
      >
        {!isAuth ? (
          <BottomNavigation showLabels value={navValue} onChange={handleNavChange}>
            <BottomNavigationAction label="Start" value={URL_PATHS.HOME} icon={<HomeOutlinedIcon />} />
            <BottomNavigationAction label="O nas" value={URL_PATHS.ABOUT} icon={<InfoOutlinedIcon />} />
            <BottomNavigationAction label="Rejestracja" value={URL_PATHS.SIGNUP} icon={<HowToRegOutlinedIcon />} />
            <BottomNavigationAction label="Zaloguj" value={URL_PATHS.LOGIN} icon={<LoginOutlinedIcon />} />
            <BottomNavigationAction label="Więcej" value="more" icon={<MenuIcon />} />
          </BottomNavigation>
        ) : isContractor ? (
          <BottomNavigation showLabels value={navValue} onChange={handleNavChange}>
            <BottomNavigationAction label="Profil" value={URL_PATHS.DASHBOARD_SUMMARY} icon={<PersonOutlineIcon />} />
            <BottomNavigationAction
              label="Wiadomości"
              value={URL_PATHS.CHAT}
              icon={
                <Badge
                  badgeContent={
                    loadingNotificationsAndMessages
                      ? null
                      : unreadMessagesCount === 0
                        ? null
                        : ` ${unreadMessagesCount}`
                  }
                  color="primary"
                  max={99}
                  overlap="circular"
                >
                  <ChatBubbleOutlineIcon />
                </Badge>
              }
            />
            <BottomNavigationAction label="Fuchy" value={URL_PATHS.FUCHA_LISTING} icon={<SearchIcon />} />
            <BottomNavigationAction
              label="Powiadomienia"
              value={`${URL_PATHS.DASHBOARD}?tab=powiadomienia`}
              icon={
                <Badge
                  badgeContent={
                    loadingNotificationsAndMessages
                      ? null
                      : unreadNotificationCount === 0
                        ? null
                        : ` ${unreadNotificationCount}`
                  }
                  color="primary"
                  max={99}
                  overlap="circular"
                >
                  <NotificationsNoneIcon />
                </Badge>
              }
            />
            <BottomNavigationAction label="Więcej" value="more" icon={<MenuIcon />} />
          </BottomNavigation>
        ) : (
          <BottomNavigation showLabels value={navValue} onChange={handleNavChange}>
            <BottomNavigationAction label="Profil" value={URL_PATHS.DASHBOARD_SUMMARY} icon={<PersonOutlineIcon />} />

            <BottomNavigationAction
              label="Wiadomości"
              value={URL_PATHS.CHAT}
              icon={
                <Badge
                  badgeContent={
                    loadingNotificationsAndMessages
                      ? null
                      : unreadMessagesCount === 0
                        ? null
                        : ` ${unreadMessagesCount}`
                  }
                  color="primary"
                  max={99}
                  overlap="circular"
                >
                  <ChatBubbleOutlineIcon />
                </Badge>
              }
            />

            <BottomNavigationAction
              label="Wystaw"
              value={`${URL_PATHS.DASHBOARD}?tab=wystaw-fuche`}
              icon={<AddCircleOutlineIcon />}
            />
            <BottomNavigationAction
              label="Powiadomienia"
              value={`${URL_PATHS.DASHBOARD}?tab=powiadomienia`}
              icon={
                <Badge
                  badgeContent={
                    loadingNotificationsAndMessages
                      ? null
                      : unreadNotificationCount === 0
                        ? null
                        : ` ${unreadNotificationCount}`
                  }
                  color="primary"
                  max={99}
                  overlap="circular"
                >
                  <NotificationsNoneIcon />
                </Badge>
              }
            />
            <BottomNavigationAction label="Więcej" value="more" icon={<MenuIcon />} />
          </BottomNavigation>
        )}
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {!isAuth ? <AnonymousDrawerList /> : <UserDrawerList />}
        </Drawer>
      </Paper>
    </ThemeProvider>
  )
}

export default BottomNav

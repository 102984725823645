import React from 'react'

const Input = ({ register, counter, name, error, label, onInput, wrapperClass, containerClassName, icon, ...rest }) => {
  return (
    <div className={containerClassName}>
      <div className="form-inner">
        {label && <label htmlFor={name}>{label}</label>}
        <input {...register(name)} onInput={onInput} aria-invalid={error ? 'true' : 'false'} {...rest} />
        {icon}
        {error && <div className="error-message">{error}</div>}
        {counter && <div className="title-text-counter">{counter}</div>}
      </div>
    </div>
  )
}

export default Input

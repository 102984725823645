import React from 'react'

const SimpleBox = (props) => {
  const { title, text, icon, number, src } = props
  return (
    <div className="d-flex justify-content-center col-xl-4 col-lg-6 col-md-6 col-sm-10">
      <div className="single-feature hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
        {number && <span className="sn">{number}</span>}
        {icon && src && (
          <div className="icon">
            <img style={{ height: '65px' }} alt="images" src={src} className="work-img" />
          </div>
        )}
        <div className="content">
          <h5>{title}</h5>
          <p className="para">{text}</p>
        </div>
      </div>
    </div>
  )
}

export default SimpleBox

import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import TermsAndConditionsContent from './TermsAndConditionsContent'

function TermsAndConditions() {
  return (
    <>
      <Breadcrumb pageName="Regulamin" pageTitle="Regulamin seriwsu mamfuche.pl" />
      <TermsAndConditionsContent />
    </>
  )
}

export default TermsAndConditions

import { z } from 'zod'

export const SendBidSchema = z.object({
  message: z.optional(z.string().max(200, 'Wiadomość jest za długa. Maksymalna długość wiadomości to 200 znaków.')),
  bid: z.coerce
    .number()
    .int('Oferta musi być liczbą')
    .gte(1, 'Minimalna wartość oferty to 1zł')
    .lte(9999999, 'Maksymalna wartość oferty to 9999999zł'),
})

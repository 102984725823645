import React from 'react'
import { Link } from 'react-router-dom'
import FuchaIcon from '../../../assets/images/icons/animation-gif2.gif'
import { URL_PATHS } from '../../../_constants'
import { scrollToTop } from '../../../_helpers'

const WhoWeAreArea = () => {
  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-top"
          alt="section-bg"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xxl-6 col-lg-10 col-md-10">
              <div className="about-img-area">
                <div className="total-tag">
                  <img className="d-xl-block d-lg-none wow fadeInUp" src={FuchaIcon} alt="images" />
                </div>
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-img.png'}
                  className="img-fluid about-img wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                  alt="about-img"
                />
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-linear.png'}
                  className="img-fluid about-linear"
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + '/images/bg/about-vector.png'}
                  className="img-fluid about-vector"
                  alt=""
                />
              </div>
            </div>
            <div className="about-content-container col-xxl-6 col-lg-10 col-md-10">
              {/* BIG DEVICES */}
              <div className="about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <p className="header-text">Kim Jesteśmy</p>
                <h2>
                  <span className="logo-text">mamfuche.pl</span> to wyjątkowe miejsce gdzie to fuchy szukają swoich
                  wykonawców!
                </h2>
                <p className="para">
                  Jesteśmy miejscem, które w wygodny i łatwy sposób, bez żadnych ukrytych kosztów, łączy osoby chcące
                  ogłosić swoją fuchę i te, które szukają odpowiedniej fuchy do wykonania!
                </p>
                <p className="para">
                  Masz do wykonania mały remont? Szukasz kogoś kto położy płytki, pomaluje ściany, wybuduje altankę,
                  garaż albo ułoży panele? Opublikuj swoją fuchę już dziś i rozpocznij swój wymarzony remont. Jeszcze
                  nigdy to nie było tak łatwe.
                </p>
                <p className="para">
                  A może jesteś wykonawcą -„złotą rączką” albo chciałbyś sobie dorobić wykonując dodatkowe fuchy?
                  Zarejestruj się już dziś i zyskaj dostęp do setek ogłoszeń z fuchami w Twojej okolicy. To takie
                  proste!
                </p>
                <Link className="eg-btn btn--primary btn--md mt-2" to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                  Zarejestruj konto
                </Link>
              </div>

              {/* SMALL DEVICES */}
              <div className="small-devices-about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <img className="small-devices-image" src={FuchaIcon} alt="images" />
                <div>
                  <p className="header-text">Kim Jesteśmy</p>
                  <h2>
                    <span className="logo-text">mamfuche.pl</span> wyjątkowe miejsce gdzie to fuchy szukają swoich
                    wykonawców!
                  </h2>
                  <p className="para">
                    Jesteśmy miejscem, które w wygodny i łatwy sposób, bez żadnych ukrytych kosztów, łączy osoby chcące
                    ogłosić swoją fuchę i te, które szukają odpowiedniej fuchy do wykonania!
                  </p>
                  <p className="para">
                    Masz do wykonania mały remont? Szukasz kogoś kto położy płytki, pomaluje ściany, wybuduje altankę,
                    garaż albo ułoży panele? Opublikuj swoją fuchę już dziś i rozpocznij swój wymarzony remont. Jeszcze
                    nigdy to nie było tak łatwe.
                  </p>
                  <p className="para">
                    A może jesteś wykonawcą -„złotą rączką” albo chciałbyś sobie dorobić wykonując dodatkowe fuchy?
                    Zarejestruj się już dziś i zyskaj dostęp do setek ogłoszeń z fuchami w Twojej okolicy. To takie
                    proste!
                  </p>
                  <Link
                    className="eg-btn btn--primary btn--md mt-2"
                    to={URL_PATHS.SIGNUP}
                    onClick={() => scrollToTop()}
                  >
                    Zarejestruj konto
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeAreArea

import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { useFuchaActions } from '../../../_actions'
import SkeletonFuchaCard from '../../common/SkeletonFuchCard'
import FuchaCard from '../fuchaListing/FuchaCard'

const upComingSlider = {
  slidesPerView: 1,
  speed: 1000,
  spaceBetween: 24,
  loop: true,
  roundLengths: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: 'true',
  },
  navigation: {
    nextEl: '.coming-prev2',
    prevEl: '.coming-next2',
  },

  breakpoints: {
    280: {
      slidesPerView: 1,
      pagination: false,
    },
    480: {
      slidesPerView: 1,
      pagination: false,
    },
    768: {
      slidesPerView: 2,
      pagination: false,
    },
    992: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
}

SwiperCore.use([Navigation, Autoplay])

const LatestFuchyArea = () => {
  const { getFuchaLatest } = useFuchaActions()
  const [loading, setLoading] = useState(true)
  const [fuchaLatest, setFuchaLatest] = useState([])

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  useEffect(() => {
    getFuchaLatest()
      .then((resp) => {
        setFuchaLatest([...resp])
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const getSkeletonFuchaCards = () => {
    const cards = []
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div key={i} className="col-lg-4 col-md-6 col-sm-10">
          <SkeletonFuchaCard />
        </div>
      )
    }
    return cards
  }

  return (
    <>
      <div className="live-auction latest-fuchy pb-120">
        <img
          alt="auctioniImage"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg2"
        />
        <div className="container position-relative">
          <img alt="auctioniImage" src={process.env.PUBLIC_URL + '/images/bg/dotted2.png'} className="dotted3" />
          <div className="row d-flex justify-content-center align-items-center mb-60 g-4">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title2 text-lg-start text-center">
                <h2>Najnowsze Fuchy</h2>
                <p className="mb-0">
                  Sprawdź najnowsze fuchy i zdobądź zlecenia, które pasują do Twoich umiejętności. Wybieraj spośród
                  różnych fuch i zacznij realizować projekty już teraz!
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4 text-lg-end text-center">
              <Link to={URL_PATHS.FUCHA_LISTING} onClick={scrollTop} className="eg-btn btn--primary2 btn--md">
                Przeglądaj Fuchy
              </Link>
            </div>
            <div className="col-xl-6 col-lg-4 d-lg-block w-100">
              <div className="slider-bottom d-flex justify-content-end align-items-center">
                <div className="slider-arrows coming-arrow d-flex justify-content-end gap-3">
                  <div
                    className="coming-prev2 swiper-prev-arrow"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <i className="bi bi-arrow-left" />
                  </div>
                  <div className="coming-next2 swiper-next-arrow" tabIndex={0} role="button" aria-label="Next slide">
                    {' '}
                    <i className="bi bi-arrow-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row latest-fuchy-container">
            <Swiper {...upComingSlider} className="swiper upcoming-slider2">
              <div className="swiper-wrapper">
                {loading
                  ? getSkeletonFuchaCards()
                  : fuchaLatest.map((fucha) => {
                      return (
                        <div key={fucha.id} className="col col-sm-10">
                          <SwiperSlide key={fucha.id} className="swiper-slide">
                            <FuchaCard fucha={fucha} />
                          </SwiperSlide>
                        </div>
                      )
                    })}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default LatestFuchyArea

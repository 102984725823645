export const PL_MOBILE_PHONE_NUMBER = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?(5|6|7|8)\d{2}[ -]?\d{3}[ -]?\d{3}(?!\w)/
export const PHONE_NUMBER_9_DIGITS = /^(?:\d{9}|\d{3}-\d{3}-\d{3})$/
export const LETTERS_ONLY = /^[A-Za-z]+$/

// Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const PASSWORD_VALIDATION = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)

// URL regex
export const URL_REGEX =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/

export const FACEBOOK_URL_REGEX = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/
export const INSTAGRAM_URL_REGEX = /(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/?/
export const TIKTOK_URL_REGEX = /(?:(?:http|https):\/\/)?(?:www.)?tiktok.com\/?/

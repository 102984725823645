import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import WhyChooseUs from '../howItWork/WhyChooseUs'
import AboutTestimonial from './AboutTestimonial'
import WhoWeAreArea from './WhoWeAreArea'

const About = () => {
  return (
    <>
      <Breadcrumb pageName="Kim Jesteśmy" pageTitle="Dlaczego warto wybrać mamfuche.pl?" />
      <WhoWeAreArea />
      <WhyChooseUs />
      <AboutTestimonial />
      {/* <AboutUsCounter /> */}
    </>
  )
}

export default About

import { useState } from 'react'

export const useMultiStepForm = (steps) => {
  // States
  const [currentStep, setCurrentStep] = useState(0)

  // Get the next step from the array
  const nextStep = () => {
    setCurrentStep((prev) => {
      if (prev < steps.length - 1) return prev + 1
      return prev
    })
  }

  // Get the previous step in the steps array
  const previousStep = () => {
    setCurrentStep((prev) => {
      if (prev > 0) return prev - 1
      return prev
    })
  }

  // Go to any step
  const goToStep = (index) => {
    setCurrentStep(index)
  }

  return {
    nextStep,
    previousStep,
    goToStep,
    Step: steps[currentStep],
    currentStep,
    isLastStep: currentStep === steps.length - 1,
    isFirstStep: currentStep === 0,
  }
}

export default useMultiStepForm

import React from 'react'
import { ContactSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { API_URLS } from '../../../_constants'
import PulseLoader from 'react-spinners/PulseLoader'
import { CONTACT_SUBJECTS } from './constants'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { useApi } from '../../../_helpers'
import { useAlerts } from '../../../context/AlertContext'

const ContactWrapper = () => {
  const api = useApi()
  const { addAlert } = useAlerts()
  const subjectOptions = CONTACT_SUBJECTS.map((subject) => ({ value: subject, label: subject }))
  const {
    register,
    handleSubmit,
    control: controlContactForm,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm({
    resolver: zodResolver(ContactSchema),
  })

  const handleSendContact = async (data) => {
    try {
      const response = await api.post(API_URLS.SEND_CONTACT_FORM, data)
      if (response.statusCode !== 200) {
        addAlert('Nie udało się wysłać wiadomości. Spróbuj ponownie później.', 'danger')
      } else {
        addAlert('Wiadomość została wysłana. Wkrótce skontaktujemy się z Tobą.', 'success')
        reset()
      }
    } catch (error) {
      addAlert('Nie udało się wysłać wiadomości. Spróbuj ponownie później.', 'danger')
    }
  }

  return (
    <>
      <div className="contact-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-6 col-lg-8 m-auto">
              <div className="form-wrapper wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title2">
                  <h3>Bądź z nami w kontakcie</h3>
                  <p className="para">
                    Jeżeli masz jakiekolwiek pytania, wypełnij fomularz, a my postaramy się odpowiedzieć najszybciej jak
                    to możliwe.
                  </p>
                </div>
                <form className="w-100" onSubmit={handleSubmit(handleSendContact)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inner">
                        <Controller
                          control={controlContactForm}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              className="contact-form-inner"
                              classNamePrefix="contact-form-inner"
                              name={name}
                              inputRef={ref}
                              value={subjectOptions.find((option) => option.value === value)}
                              options={subjectOptions}
                              placeholder={<div className="select-placeholder-text">Wybierz temat</div>}
                              onChange={(selectedOption) => onChange(selectedOption.value)}
                              isSearchable={false}
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  cursor: 'pointer',
                                  textAlign: 'left',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  textAlign: 'left',
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  textAlign: 'left',
                                }),
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: '0.25rem',
                                cursor: 'pointer',
                                colors: {
                                  ...theme.colors,
                                  primary: '#f9395f',
                                  primary25: '#f9395f',
                                  height: '54px',
                                },
                              })}
                            />
                          )}
                          name={'subject'}
                        />
                        {errors.subject && <div className="error-message">{errors.subject.message}</div>}
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input {...register('name')} placeholder="Imię:" />
                        {errors.name && <div className="error-message">{errors.name.message}</div>}
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <div className="form-inner">
                        <input {...register('phone')} placeholder="Numer Telefonu:" />
                        {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-inner">
                        <input {...register('email')} type="email" placeholder="Email:" />
                        {errors.email && <div className="error-message">{errors.email.message}</div>}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-inner">
                        <textarea
                          {...register('message')}
                          placeholder="Napisz wiadomość :"
                          rows={12}
                          defaultValue={''}
                          maxLength="2000"
                          onInput={(e) => {
                            const form = e.target.closest('form')
                            const counter = form.querySelector('.text-counter:last-child')
                            counter.textContent = `${e.target.value.length}/2000`
                          }}
                        />

                        {errors.message && <div className="error-message">{errors.message.message}</div>}
                        <div className="text-counter">0/2000</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="eg-btn btn--primary btn--md form--btn">
                        <PulseLoader color="white" loading={isSubmitting} size={10} />
                        {!isSubmitting && 'Wyślij Wiadomość'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-area wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/contact.svg'} className="work-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactWrapper

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PulseLoader from 'react-spinners/PulseLoader'

const ConfirmModal = ({
  className,
  header,
  children,
  showModal,
  handleClose,
  handleConfirm,
  size = 'sm',
  isCentered = true,
  confirmButton = true,
  cancelButton = true,
  closeButton,
  hasFooter = true,
}) => {
  const [isConfirming, setIsConfirming] = useState(false)
  return (
    <Modal size={size} show={showModal} onHide={handleClose} centered={isCentered} className={className}>
      <Modal.Header closeButton className="sidebar-widget-title">
        <Modal.Title>
          <h4>{header}</h4>
          <span />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {hasFooter && (
        <Modal.Footer>
          {cancelButton && (
            <Button variant="secondary" className="eg-btn btn--sm" onClick={handleClose}>
              Anuluj
            </Button>
          )}
          {confirmButton && (
            <Button
              variant="dark"
              className="eg-btn btn--primary btn--sm"
              onClick={() => {
                setIsConfirming(true)
                handleConfirm(setIsConfirming)
              }}
              disabled={isConfirming}
            >
              {isConfirming ? <PulseLoader color="white" loading={true} size={10} /> : 'Potwierdź'}
            </Button>
          )}
          {closeButton && (
            <Button variant="dark" className="eg-btn btn--sm" onClick={handleClose}>
              Zamknij
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default ConfirmModal

import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useAlerts } from '../../../context/AlertContext'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { PasswordResetSchema } from './schema'
import { useUserActions } from '../../../_actions'
import { scrollToTop } from '../../../_helpers'

const PasswordReset = () => {
  let userAction = useUserActions()
  const { auth } = useAuth()
  const [sent, setSent] = useState(false)
  const { addAlert } = useAlerts()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm({
    resolver: zodResolver(PasswordResetSchema),
  })

  const handlePasswordReset = (data) => {
    setSent(true)
    userAction
      .passwordReset(data)
      .then((response) => {})
      .catch((error) => {
        addAlert('Wystąpił błąd podczas wysyłania wiadomości e-mail. Spróbuj ponownie.', 'danger')
      })
  }

  if (auth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <>
      <div className="login-section">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <img
          alt="imges"
          src={process.env.PUBLIC_URL + '/images/bg/client-bg-small.svg'}
          className="img-fluid section-bg-small"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Zresetuj hasło</h3>
                  <p className="login-text reset-text">
                    Po wysłaniu formularza otrzymasz wiadomość <b>e-mail z linkiem</b> do zresetowania hasła. Link
                    będzie <b>aktywny do 72 godzin</b>.
                  </p>
                </div>

                {/* ----------- FORM START ------------- */}
                {sent ? (
                  <div className="form-title reset-form-title">
                    <p className="login-text reset-text text-center">
                      Jeśli podane <b>konto istnieje</b>, wysłaliśmy na nie wiadomość <b>e-mail z instrukcjami</b>.
                      Prosimy o sprawdzenie skrzynki odbiorczej i postępowanie zgodnie z zamieszczonymi tam wskazówkami,
                      aby zresetować hasło.
                    </p>
                    <div className="col-12 d-flex justify-content-center">
                      <Link
                        to={URL_PATHS.LOGIN}
                        onClick={() => scrollToTop()}
                        className="eg-btn btn--primary card--btn p-2 mx-auto mt-4"
                      >
                        Przejdź do logowania
                      </Link>
                    </div>
                  </div>
                ) : (
                  <form className="w-100 login-form" onSubmit={handleSubmit(handlePasswordReset)}>
                    <div className="row">
                      <div className="lg-col-12">
                        <div className="form-inner">
                          <label>Email *</label>
                          <input {...register('email')} type="email" name="email" id="email" placeholder="Email" />
                          <div className="email-error">
                            {errors.email && <div className="error-message">{errors.email.message}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="lg-col-12">
                        <div
                          style={{ marginTop: '-15px' }}
                          className="reset-pass-container form-inner d-flex justify-content-between flex-wrap mb-3"
                        >
                          <Link to={URL_PATHS.LOGIN} onClick={() => scrollToTop()} className="reset-pass">
                            Zaloguj się
                          </Link>
                        </div>
                      </div>
                    </div>
                    <button className="account-btn">Zresetuj hasło</button>
                  </form>
                )}

                {/* ----------- FORM END ------------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordReset

import React, { useEffect, useState } from 'react'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { getFormattedDate } from '../../../../_helpers'
import { useAuth } from '../../../../context/AuthContext'
import { useFuchaActions } from '../../../../_actions'
import { shouldDisplayServerSideError } from '../../../../_helpers'
import { useAlerts } from '../../../../context/AlertContext'
import { history } from '../../../../_helpers'

const FuchaOwnerInfoCard = ({ fucha, maybeUser, disabledFuchaAction }) => {
  const [clientDetails, setClientDetails] = useState({})
  const owner = fucha.user
  const { addAlert } = useAlerts()
  const { auth } = useAuth()
  const { getClientDetails, startChat } = useFuchaActions()
  const isContractor = auth?.user?.is_contractor || false
  const isAcceptedBidContractor = fucha?.accepted_bid?.contractor?.id === maybeUser.id

  const handleChatStart = () => {
    if (!isContractor) {
      addAlert('Nie posiadasz uprawnień do rozmówienia z właścicielem fuchy', 'danger')
      return
    }
    startChat(fucha.id, maybeUser.id)
      .then((data) => {
        history.push('/chat?chatId=' + data.channel_id)
      })
      .catch((err) => {
        addAlert('Wystąpił błąd podczas tworzenia chatu', 'danger')
      })
  }

  useEffect(() => {
    if (isAcceptedBidContractor) {
      getClientDetails(fucha.id)
        .then((data) => {
          setClientDetails(data)
        })
        .catch((err) => {
          if (shouldDisplayServerSideError(err)) {
            addAlert(err.response.data.display, 'danger')
          } else {
            addAlert('Wystąpił błąd podczas pobierania danych', 'danger')
          }
        })
    }
  }, [])

  return (
    <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Poznaj Właściciela Fuchy</h4>
          <span />
        </div>
        <div className="image-container">
          <div className="image-box">
            <img src={owner.image ? owner.image : AccountIcon} alt="Admin" className="rounded-circle" width="100" />
          </div>
        </div>
        <div className="client-details-container">
          <div className="information-container">
            <div className="client-name-container">
              <div className="name-container">
                <p className="name">{owner.first_name}</p>
              </div>
              {/* <div className="review-rate">
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </div> */}
            </div>
            <div className="icon-container">
              <div className="icon">
                <i className="bi bi-calendar-check"></i>
              </div>
              <p>Z mamfuche.pl od {getFormattedDate(owner.date_joined, false)}</p>
            </div>
            <div className="verification-info-container">
              {isAcceptedBidContractor ? (
                <>
                  <div
                    className={`icon-container offer-icon-container ${owner.is_email_verified ? 'verified' : 'not-verified'}`}
                  >
                    <div className="icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p>{clientDetails.email}</p>
                  </div>
                  <div className={`icon-container ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                    <div className="icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <p>{clientDetails.phone_number}</p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`icon-container offer-icon-container ${owner.is_email_verified ? 'verified' : 'not-verified'}`}
                  >
                    <div className="icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p className={`verify-text ${owner.is_email_verified ? 'verified' : 'not-verified'}`}>
                      {owner.is_email_verified ? 'Zweryfikowany adres email' : 'Niezweryfikowany adres email'}
                    </p>
                  </div>
                  <div className={`icon-container ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                    <div className="icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <p className={`verify-text ${owner.is_phone_verified ? 'verified' : 'not-verified'}`}>
                      {owner.is_phone_verified ? 'Zweryfikowany numer telefonu' : 'Niezweryfikowany numer telefonu'}
                    </p>
                  </div>
                </>
              )}
            </div>
            {isContractor && (
              <button className="eg-btn btn--primary btn--sm mt-3 mb-3" onClick={() => handleChatStart()}>
                Napisz wiadomość
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuchaOwnerInfoCard

export const FUCHA_STATUS = Object.freeze({
  ACTIVE: 'aktywna',
  ARCHIVED: 'zarchiwizowana',
  CANCELLED: 'anulowana',
  DELETED: 'usunięta',
  EXPIRED: 'przedawniona',
  FINISHED: 'zakończona',
  INACTIVE: 'nieaktywna',
})

export const FUCHA_FILTERS = Object.freeze({
  CATEGORIES: 'kategoria',
  COUNTIES: 'region',
  PROVINCES: 'województwo',
  TYPES_OF_WORK: 'typ-pracy',
  WORK_STATE: 'stan-pracy',
})

export const WORK_STATE = Object.freeze({
  NEW_BUILD: 'deweloperski',
  OTHER: 'inne',
  RENOVATION: 'remont',
})

export const WORK_STATE_VALUES = Object.values(WORK_STATE)

export const FUCHA_CANCELLABLE_STATUSES = [FUCHA_STATUS.ACTIVE, FUCHA_STATUS.INACTIVE]
export const FUCHA_DELETABLE_STATUSES = [FUCHA_STATUS.CANCELLED, FUCHA_STATUS.ARCHIVED]

export const FUCHA_LISTING_PER_PAGE = 15

export const MAX_NUMBER_OF_BIDDING_CONTRACTORS = 15
export const MAX_NUMBER_OF_ACTIVE_BIDS = 20
export const ADD_BID_COUNT_CHANGE = 1
export const REMOVE_BID_COUNT_CHANGE = -1

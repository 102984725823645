import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AddressSchema } from './schemas/addressSchema'
import { useUser } from '../../../../context/UserContext'
import Form from '../../../common/Form'
import Input from '../../../common/Input'
import { useForm, watch, Controller } from 'react-hook-form'
import { useUserActions } from '../../../../_actions/user.actions'
import { useAlerts } from '../../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'
import Select from 'react-select'
import { REGIONS } from '../../../../_constants'

const provinces = Object.keys(REGIONS)

const AddressForm = ({ user }) => {
  const { setUser } = useUser()
  const { updateAddress } = useUserActions()
  const { addAlert } = useAlerts()
  const [counties, setCounties] = useState([])
  const [isCountyDisabled, setIsCountyDisabled] = useState(true)
  const [isCityDisabled, setIsCityDisabled] = useState(true)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(AddressSchema),
  })

  const onSubmit = async (data) => {
    const payload = {
      city: data.city,
      county: data.county,
      province: data.province,
      street: data.street,
      house_number: data.houseNumber,
      postcode: data.postCode,
      flat_number: data.flatNumber,
    }

    setUser({ ...user, address: payload })

    try {
      await updateAddress(payload)
      addAlert('Twój adres został zaktualizowany', 'success')
    } catch (error) {
      addAlert('Wystąpił błąd podczas aktualizacji adresu. Spróbuj ponownie.', 'danger')
    }
  }

  const handleProvinceChange = (value) => {
    const counties = REGIONS[value]
    let countiesSelect
    for (let i = 0; i < counties.length; i++) {
      countiesSelect = counties.map((county) => {
        return { value: county, label: county }
      })
    }
    setCounties(countiesSelect)
    setValue('city', '')
    setIsCountyDisabled(false)
  }

  const provinceValues = () => {
    return provinces.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
  }

  const provinceOptions = provinceValues()

  const handleCityChange = () => {
    const county = watch('county')
    setIsCityDisabled(true)

    if (county && !county.includes('Pow')) {
      setValue('city', county)
    } else {
      setValue('city', '')
      setIsCityDisabled(false)
    }
  }

  useEffect(() => {
    if (user?.address?.province) {
      setValue('province', user?.address.province)
      handleProvinceChange(user?.address.province)
    }
    if (user?.address?.county) {
      setValue('county', user?.address.county)
      if (!user?.address.county.includes('Pow')) {
        setValue('city', user?.address.county)
        setIsCityDisabled(true)
      } else {
        setValue('city', user?.address?.city)
        setIsCityDisabled(false)
      }
    }
  }, [])

  return (
    <Form
      buttonLabel="Aktualizuj"
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      className="eg-btn profile-btn"
      isSubmitting={isSubmitting}
    >
      <div className="form-section-title">
        <p>Adres</p>
      </div>
      <div className="col-xl-6 col-lg-12 col-md-6">
        <div className="form-inner address-form-inner text-start">
          <label>Województwo</label>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                className="address-form-inner"
                classNamePrefix="address-form-inner"
                name={name}
                defaultValue={
                  user?.address?.province ? { label: user?.address.province, value: user?.address.province } : null
                }
                inputRef={ref}
                value={provinceOptions.find((province) => {
                  return province.value === value
                })}
                // value={provinceOptions.find((province) => province.value === value)}
                options={provinceOptions}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value)
                  handleProvinceChange(selectedOption.value)
                }}
                placeholder={<div className="select-placeholder-text">Wybierz Województwo</div>}
                isSearchable={false}
                noOptionsMessage={() => 'Brak opcji'}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                }}
              />
            )}
            name={'province'}
          />
          {errors && <div className="error-message">{errors.province?.message}</div>}
        </div>
      </div>
      <div className="col-xl-6 col-lg-12 col-md-6">
        <div className="form-inner address-form-inner text-start">
          <label>Region</label>
          <Controller
            control={control}
            render={({ field: { onChange, name, ref, value } }) => (
              <Select
                inputRef={ref}
                className="address-form-inner"
                classNamePrefix="address-form-inner"
                name={name}
                value={counties.find((c) => {
                  return c.value === value
                })}
                options={counties}
                onChange={(selectedOption) => {
                  onChange(selectedOption.value)
                  handleCityChange()
                }}
                placeholder={<div className="select-placeholder-text">Wybierz Region</div>}
                isDisabled={isCountyDisabled}
                isSearchable={false}
                noOptionsMessage={() => 'Brak opcji'}
                defaultValue={
                  user?.address?.county ? { label: user?.address.county, value: user?.address.county } : null
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f9395f',
                    primary: '#f9395f',
                  },
                })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    textAlign: 'left',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                }}
              />
            )}
            name={'county'}
          />
          {errors && <div className="error-message">{errors.county?.message}</div>}
        </div>
      </div>
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="city"
        type="text"
        label="Miejscowość"
        placeholder="Miejscowość"
        error={errors.city?.message}
        defaultValue={user?.address?.city || ''}
        disabled={isCityDisabled}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="street"
        type="text"
        label="Ulica"
        placeholder="Ulica"
        error={errors.street?.message}
        defaultValue={user?.address?.street || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="houseNumber"
        type="text"
        label="Numer domu"
        placeholder="Numer Domu"
        error={errors.houseNumber?.message}
        defaultValue={user?.address?.house_number || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="flatNumber"
        type="text"
        label="Numer mieszkania"
        placeholder="Numer mieszkania"
        error={errors.flatNumber?.message}
        defaultValue={user?.address?.flat_number || ''}
      />
      <Input
        containerClassName={'col-xl-6 col-lg-12 col-md-6 text-start'}
        name="postCode"
        type="text"
        label="Kod pocztowy"
        placeholder="Kod pocztowy (00-000)"
        error={errors.postCode?.message}
        defaultValue={user?.address?.postcode || ''}
      />
    </Form>
  )
}
export default AddressForm

AddressForm.propsTypes = {
  user: PropTypes.object.isRequired,
}

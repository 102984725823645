import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import BlogWrap from './BlogWrap'

function Blog() {
  return (
    <>
      <Breadcrumb pageTitle="Blog" pageName="Our Blog" />
      <BlogWrap />
      <AboutUsCounter />
    </>
  )
}

export default Blog

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonFuchaCard = () => {
  return (
    <>
      <div data-wow-duration="1.5s" data-wow-delay="0.2s" className="eg-card auction-card2 wow fadeInDown">
        <div className="auction-img">
          <Skeleton height={260} borderRadius={'5px'} style={{ marginTop: 0 }} />
        </div>
        <div className="auction-timer"></div>
        <div className="auction-content">
          <h4>
            <Skeleton />
            <Skeleton />
          </h4>
          <p>
            <Skeleton width={60} />
          </p>
          <div className="author-price-area">
            <div className="author">
              <p>
                <Skeleton width={30} height={30} circle={true} />
              </p>

              <span className="name">
                <Skeleton />
              </span>
            </div>
            <div>
              <h6>
                <Skeleton width={50} />
              </h6>
              <p>
                <Skeleton width={20} />
              </p>
            </div>
          </div>
          <div className="auction-card-bttm">
            <Skeleton width={90} height={30} />

            <div className="share-area">
              <Skeleton width={30} height={30} circle={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkeletonFuchaCard

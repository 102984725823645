export const getPageCount = (itemsCount, perPage) => {
  return Math.ceil(itemsCount / perPage)
}

export const getFromPageNumber = (page, perPage) => {
  return (page - 1) * perPage + 1
}

export const getToPageNumber = (page, perPage, itemsCount) => {
  return Math.min(page * perPage, itemsCount)
}

export const scrollToTop = (behavior = 'smooth') => {
  window.scrollTo({ top: 0, behavior: behavior })
}

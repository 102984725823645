import React from 'react'
import SimpleBox from '../../common/SimpleBox'
import { MAX_NUMBER_OF_BIDDING_CONTRACTORS } from '../../../_constants/fucha'

const WhyChooseUs = () => {
  return (
    <>
      <div className="choose-us-section pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'} className="section-bg-bottom" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex justify-content-center col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>Dlaczego warto wybrać mamfuche.pl?</h2>
                <p className="para mb-0">
                  <b>mamfuche.pl</b> to nowoczesna platforma, która łączy poszukujących wykonawców usług remontowych z
                  doświadczonymi fachowcami. Tutaj każdy może łatwo znaleźć odpowiednich fachowców do realizacji swoich
                  fuch!
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            <SimpleBox
              title="Bezpłatne wystawianie fuch"
              text="Wystawiaj swoje fuchy bez żadnych opłat, by szybko dotrzeć do zainteresowanych fachowców."
              src={process.env.PUBLIC_URL + '/images/icons/free.svg'}
              icon
              number="01"
            />
            <SimpleBox
              title="Bezpieczeństwo danych"
              text="Twoje dane kontaktowe są chronione. Do komunikacji z fachowcami używaj wbudowanego czatu!"
              src={process.env.PUBLIC_URL + '/images/icons/safe-data.svg'}
              icon
              number="02"
            />
            <SimpleBox
              title="Więcej szans na znalezienie fachowca!"
              text={`Na każdą fuchę może wpłynąć aż do ${MAX_NUMBER_OF_BIDDING_CONTRACTORS} wycen od fachowców!`}
              src={process.env.PUBLIC_URL + '/images/icons/multiple.svg'}
              icon
              number="03"
            />
            <SimpleBox
              title="Szeroki wybór fachowców"
              text="Dostęp do licznych fachowców, którzy zapewnią wysoką jakość usług w różnych dziedzinach."
              src={process.env.PUBLIC_URL + '/images/icons/people.svg'}
              icon
              number="04"
            />
            <SimpleBox
              title="Łatwość rejestracji"
              text="Zarejestruj się w kilka minut, wypełniając prosty formularz i od razu zacznij korzystać z platformy."
              src={process.env.PUBLIC_URL + '/images/icons/easy-signup.svg'}
              icon
              number="05"
            />
            <SimpleBox
              title="Recenzje i oceny fachowców"
              text="Sprawdź opinie i oceny innych użytkowników, aby wybrać najlepszego fachowca do realizacji twojej fuchy."
              src={process.env.PUBLIC_URL + '/images/icons/reviews.svg'}
              icon
              number="06"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyChooseUs

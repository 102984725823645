import { z } from 'zod'

const customErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === 'undefined') {
      return { message: 'To pole jest wymagane' }
    }
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)

export const AddressSchema = z.object({
  province: z.string().min(1, 'To pole jest wymagane'),
  county: z.string().min(1, 'To pole jest wymagane'),
  city: z.string().min(1, 'To pole jest wymagane').max(30, 'Maksymalna liczba znaków to 30'),
  houseNumber: z.string().min(1, 'To pole jest wymagane').max(10, 'Maksymalna liczba znaków to 10'),
  postCode: z.string().regex(/^\d{2}-\d{3}$/, { message: 'Nieprawidłowy format kodu pocztowego (00-000)' }),
  street: z.string().min(1, 'To pole jest wymagane').max(50, 'Maksymalna liczba znaków to 50'),
})

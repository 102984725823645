import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from 'react-bootstrap'

const FuchaSpecificationsCard = ({ fucha, fuchaLoading, disabledFuchaAction }) => {
  return (
    <div className="col-md-12">
      <div className={`card ${disabledFuchaAction ? 'expired-fucha' : ''}`}>
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>Specyfikacja Fuchy</h4>
            <span />
          </div>
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <Row>
              <Col lg={3} md={12}>
                <div className="icon-container specification-container">
                  <div className="icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <p>Lokalizacja</p>
                </div>
                <div className="tags">
                  <>
                    {fuchaLoading ? (
                      <>
                        <Skeleton width={80} />
                        <Skeleton width={80} />
                      </>
                    ) : (
                      <>
                        <span key={fucha.province} className="tag tag-province">
                          {fucha.province}
                        </span>
                        <span key={fucha.county} className="tag tag-county">
                          {fucha.county}
                        </span>
                      </>
                    )}
                  </>
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="icon-container specification-container">
                  <div className="icon">
                    <i className="bi bi-tools"></i>
                  </div>
                  <p>Kategoria</p>
                </div>
                <div className="tags">
                  {fuchaLoading ? (
                    <>
                      <Skeleton width={60} />
                      <Skeleton width={60} />
                    </>
                  ) : (
                    fucha.categories.map((category, index) => {
                      return (
                        <span className="tag" key={index}>
                          {category.name}
                        </span>
                      )
                    })
                  )}
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="icon-container specification-container">
                  <div className="icon">
                    <i className="bi bi-hammer"></i>
                  </div>
                  <p>Zakres Prac</p>
                </div>
                <div className="tags">
                  {fuchaLoading ? (
                    <>
                      <Skeleton width={60} />
                      <Skeleton width={60} />
                    </>
                  ) : (
                    fucha.types_of_work.map((typeOfWork, index) => {
                      return (
                        <span className="tag" key={index}>
                          {typeOfWork.name}
                        </span>
                      )
                    })
                  )}
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="icon-container specification-container">
                  <div className="icon">
                    <i className="bi bi-arrows-fullscreen"></i>
                  </div>
                  <p>Powierzchnia</p>
                </div>
                <div className="tags">
                  {fuchaLoading ? (
                    <Skeleton width={50} />
                  ) : (
                    <span className="tag tag-county">{fucha.sqm ? `${fucha.sqm} m2` : 'N/D'}</span>
                  )}
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="icon-container specification-container">
                  <div className="icon">
                    <i className="bi bi-house-door-fill"></i>
                  </div>
                  <p>Stan</p>
                </div>
                <div className="tags">
                  {fuchaLoading ? <Skeleton width={50} /> : <span className="tag tag-county">{fucha.work_state}</span>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuchaSpecificationsCard

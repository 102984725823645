import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useAlerts } from '../../../context/AlertContext'
import { useDashboardTab } from '../../../context/DashboardTabContext'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { LoginSchema } from './schema'
import { useUserActions } from '../../../_actions'
import { scrollToTop } from '../../../_helpers'
import { getQueryParams, removeAllQueryParams } from '../../../_helpers'
import PulseLoader from 'react-spinners/PulseLoader'
import { history, findDashboardTab } from '../../../_helpers'

const LoginClient = () => {
  const { activeTab, setActiveTab } = useDashboardTab()
  let userAction = useUserActions()
  let [openEye, setOpenEye] = useState()
  const { isAuth } = useAuth()
  const { addAlert } = useAlerts()
  const [emailVerificationError, setEmailVerificationError] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm({
    resolver: zodResolver(LoginSchema),
  })

  const handleEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1)
    } else {
      setOpenEye(false)
    }
  }

  const handleResendVerificationEmail = () => {
    userAction
      .resendVerificationEmail(getValues('email'))
      .then((data) => {
        addAlert('Wysłano email weryfikacyjny ponownie. Sprawdź swoją skrzynkę pocztową.', 'success')
      })
      .catch((error) => {
        addAlert(
          'Nie udało się wysłać email weryfikacyjnego. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.',
          'danger',
          null
        )
      })
    setEmailVerificationError(false)
  }

  const handleLogin = async (data) => {
    const emailLowerCase = data.email.toLowerCase()

    const loginData = {
      ...data,
      email: emailLowerCase,
    }

    try {
      await userAction.login(loginData)
      setActiveTab(findDashboardTab('podsumowanie'))
      history.push(URL_PATHS.DASHBOARD_SUMMARY)
    } catch (error) {
      if (error.response?.status === 401) {
        addAlert('Email lub hasło jest nieprawidłowe. Spróbuj jeszcze raz.', 'danger')
      } else if (error.response?.status === 400 && error.response.data.message === 'Email verification is required') {
        setEmailVerificationError(true)
      } else {
        addAlert('Nie udało się zalogować. Spróbuj jeszcze raz.', 'danger')
      }
    }
  }

  const handleEmailVerification = (userId, verificationToken) => {
    userAction
      .verifyEmail({ user_id: userId, token: verificationToken })
      .then((data) => {
        addAlert('Potwierdzono email. Możesz się teraz zalogować.', 'success')
      })
      .catch((error) => {
        if (error?.response?.data?.display) {
          addAlert(error.response.data.detail, 'danger')
        } else {
          addAlert('Nie udało się potwierdzić email. Spróbuj jeszcze raz.', 'danger')
        }
      })
  }

  useEffect(() => {
    const queryParams = getQueryParams()
    const userIdParam = queryParams.get('user_id')
    const verificationTokenParam = queryParams.get('token')
    removeAllQueryParams()
    if (userIdParam && verificationTokenParam) {
      handleEmailVerification(userIdParam, verificationTokenParam)
    }
  }, [])

  if (isAuth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <>
      <div className="login-section">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <img
          alt="imges"
          src={process.env.PUBLIC_URL + '/images/bg/client-bg-small.svg'}
          className="img-fluid section-bg-small"
        />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10 m-auto">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Zaloguj się na swoje konto</h3>
                  <p className="login-text">
                    Nie masz jeszcze konta?{' '}
                    <Link to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                      Zarejestruj się tutaj
                    </Link>
                  </p>
                </div>

                {/* ----------- FORM START ------------- */}
                <form className="w-100 login-form" onSubmit={handleSubmit(handleLogin)}>
                  <div className="row">
                    <div className="lg-col-12">
                      <div className="form-inner mb-0">
                        <label>Email *</label>
                        <input {...register('email')} type="email" name="email" id="email" placeholder="Email" />
                        <div className="email-error">
                          {errors.email && <div className="error-message">{errors.email.message}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="lg-col-12">
                      <div className="form-inner mb-0">
                        <label>Hasło *</label>
                        <input
                          type={openEye === 1 ? 'text' : 'password'}
                          name="password"
                          id="password"
                          placeholder="Hasło"
                          {...register('password')}
                        />
                        <i
                          className={openEye === 1 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                          id="togglePassword"
                          onClick={handleEyeIcon}
                        />
                      </div>
                      <div className="password-error">
                        {errors.password && <div className="error-message">{errors.password.message}</div>}
                      </div>
                    </div>
                    {emailVerificationError && (
                      <div
                        className="error-message text-decoration-underline"
                        onClick={handleResendVerificationEmail}
                        role="button"
                      >
                        Aby zalogować się, musisz najpierw zweryfikować swój email. Kliknij <u>tutaj</u>, aby wysłać
                        ponownie email aktywacyjny.
                      </div>
                    )}
                    <div className="lg-col-12">
                      <div className="forgot-pass-container form-inner d-flex justify-content-between flex-wrap">
                        <Link to={URL_PATHS.PASSWORD_RESET} onClick={() => scrollToTop()} className="forgot-pass">
                          Nie pamiętam hasła
                        </Link>
                      </div>
                    </div>
                  </div>
                  <button className="account-btn spinner-white-bg" disabled={isSubmitting}>
                    <PulseLoader color="white" loading={isSubmitting} size={10} />
                    {!isSubmitting && 'Zaloguj się'}
                  </button>
                </form>
                {/* ----------- FORM END ------------- */}

                {/* ----------- GOOGLE/FACEBOOK ------------- */}
                {/* <div className="alternate-signup-box">
                  <h6>szybkie logowanie</h6>
                  <div className="btn-group gap-4">
                    <a href className="eg-btn google-btn d-flex align-items-center">
                      <i className="bx bxl-google" />
                      <span>zaloguj się z Google</span>
                    </a>
                    <a href className="eg-btn facebook-btn d-flex align-items-center">
                      <i className="bx bxl-facebook" />
                      zaloguj się z facebook
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginClient

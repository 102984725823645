import { useApi } from '../_helpers'
import { API_URLS, DYNAMIC_API_URLS } from '../_constants'

export const useClientActions = () => {
  const api = useApi()

  return {
    acceptBid,
    cancelFucha,
    getClientDashboardData,
    getMyFuchy,
    createContractorReview,
  }

  async function acceptBid(payload) {
    const response = await api.post(API_URLS.CLIENT_ACCEPT_BID, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function cancelFucha(id) {
    const response = await api.delete(DYNAMIC_API_URLS.CancelMyFucha(id))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getClientDashboardData() {
    const response = await api.get(API_URLS.CLIENT_DASHBOARD_DATA)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getMyFuchy(page) {
    const response = await api.get(`${API_URLS.CLIENT_MY_FUCHY}?page=${page}`)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function createContractorReview(payload) {
    const response = await api.post(API_URLS.CREATE_REVIEW, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

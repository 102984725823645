import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/Breadcrumb'
import { useAuth } from '../../../context/AuthContext'
import AboutUsCounter from '../../common/AboutUsCounter'
import { useForm } from 'react-hook-form'
import { SignUpSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { URL_PATHS, API_URLS } from '../../../_constants/urls'
import { useHistory } from 'react-router-dom'
import { useAlerts } from '../../../context/AlertContext'
import { useUserActions } from '../../../_actions'
import { scrollToTop } from '../../../_helpers'
import PulseLoader from 'react-spinners/PulseLoader'

const SignUpContractor = () => {
  const [openEye, setOpenEye] = useState(false)
  const { addAlert } = useAlerts()
  const { isAuth } = useAuth()
  const userActions = useUserActions()

  const handlePasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1)
    } else {
      setOpenEye(false)
    }
  }

  const handleConfirmPasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(2)
    } else {
      setOpenEye(false)
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
  })

  let history = useHistory()

  const onSubmit = async (data) => {
    const payload = {
      email: data.email.toLowerCase(),
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      is_marketing_enabled: data.marketingComms,
      is_external_marketing_enabled: data.externalMarketingComms,
      is_contractor: true,
    }
    try {
      await userActions.register(payload)
      addAlert('Konto utworzone pomyślnie! Sprawdź skrzynkę pocztową i potwierdź swój adres e-mail.', 'success', 10000)
      reset()
      scrollToTop()
      history.push('/login')
    } catch (error) {
      if (error.response?.data?.display && error.response?.data?.detail) {
        addAlert(error.response.data.detail, 'danger')
      } else {
        addAlert('Wystąpił błąd podczas tworzenia konta. Spróbuj ponownie za chwilę.', 'danger', null)
      }
    }
  }

  if (isAuth) {
    scrollToTop()
    return <Redirect to={URL_PATHS.DASHBOARD} />
  }

  return (
    <>
      <div className="contractor">
        <Breadcrumb pageName="Jesteś fachowcem?" pageTitle="Zarejestruj tutaj konto aby móc składać wyceny!" />
      </div>

      <div className="signup-section pt-120 pb-120">
        <img alt="imges" src={process.env.PUBLIC_URL + '/images/bg/client-bg.svg'} className="img-fluid section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Zarejestruj konto i łap najlepsze fuchy!</h3>
                  <p className="signup-text">
                    Masz już konto?{' '}
                    <Link to={URL_PATHS.LOGIN} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                      Zaloguj się tutaj
                    </Link>
                  </p>
                  <div className="eg-btn btn--primary p-2 join-btn w-100 mt-4">
                    <Link to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                      Chcesz zlecić fuchę? Kliknij tutaj!
                    </Link>
                  </div>
                </div>

                {/* ----------- FORM START ------------- */}

                <form onSubmit={handleSubmit(onSubmit)} className="w-100 registration-form">
                  <div className="row">
                    {/* ----------- First Name ------------- */}
                    <div className="col-md-6">
                      <div className="form-inner mb-3">
                        <label>Imię *</label>
                        <input {...register('firstName')} type="text" placeholder="Imię" />
                        {errors.firstName && <div className="error-message">{errors.firstName.message}</div>}
                      </div>
                    </div>

                    {/*----------- Last Name ----------- */}
                    <div className="col-md-6">
                      <div className="form-inner mb-3">
                        <label>Nazwisko *</label>
                        <input {...register('lastName')} type="text" placeholder="Nazwisko" />
                        {errors.lastName && <div className="error-message">{errors.lastName.message}</div>}
                      </div>
                    </div>

                    {/* ----------- Email ----------- */}
                    <div className="col-md-12">
                      <div className="form-inner mb-3">
                        <label>Email *</label>
                        <input {...register('email')} type="email" placeholder="Email" />
                        {errors.email && <div className="error-message">{errors.email.message}</div>}
                      </div>
                    </div>

                    {/* ----------- Phone Number ----------- */}
                    <div className="col-md-12">
                      <div className="form-inner mb-3">
                        <label>Numer Telefonu *</label>
                        <input {...register('phoneNumber')} type="text" placeholder="Numer Telefonu" />
                        {errors.phoneNumber && <div className="error-message">{errors.phoneNumber.message}</div>}
                      </div>
                    </div>

                    {/* ----------- Password -----------*/}
                    <div className="col-md-12">
                      <div style={{ marginBottom: '8px' }} className="form-inner">
                        <label>Hasło *</label>
                        <input
                          {...register('password')}
                          type={openEye === 1 ? 'text' : 'password'}
                          name="password"
                          id="password"
                          placeholder="Hasło"
                        />
                        <i
                          className={openEye === 1 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                          onClick={handlePasswordEyeIcon}
                          id="togglePassword"
                        />
                      </div>
                      <div className="password-error">
                        {errors.password && <div className="error-message">{errors.password.message}</div>}
                      </div>
                    </div>

                    {/* ----------- Confirm Password ----------- */}
                    <div className="col-md-12">
                      <div style={{ marginBottom: '8px' }} className="form-inner">
                        <label>Powtórz Hasło *</label>
                        <input
                          {...register('confirmPassword')}
                          type={openEye === 2 ? 'text' : 'password'}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Powtórz Hasło"
                        />
                        <i
                          className={openEye === 2 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
                          onClick={handleConfirmPasswordEyeIcon}
                          id="toggleConfirmPassword"
                        />
                      </div>
                      <div className="password-error">
                        {errors.confirmPassword && (
                          <div className="error-message">{errors.confirmPassword.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input {...register('termsAndConditions')} type="checkbox" id="termsAndConditions" />
                          <label htmlFor="termsAndConditions">
                            <span className="color-primary">*</span>
                            <span>Akceptuję </span>
                            <u>
                              <Link to={URL_PATHS.TERMS_AND_CONDITIONS} target="_blank">
                                Regulamin
                              </Link>
                            </u>
                            <span>
                              {' '}
                              oraz{' '}
                              <u>
                                <Link to={URL_PATHS.PRIVACY_POLICY} target="_blank">
                                  Politykę Prywatności
                                </Link>
                              </u>{' '}
                              platformy mamfuche.pl
                            </span>
                          </label>
                          {errors.termsAndConditions && (
                            <div style={{ marginTop: '-10px', fontSize: '12px' }} className="error-message">
                              {errors.termsAndConditions.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input {...register('marketingComms')} type="checkbox" id="marketingComms" />
                          <label htmlFor="marketingComms">
                            <span>
                              Chcę otrzymywać od mamfuche.pl oferty specjalne oraz inne treści marketingowe dopasowane
                              do mnie. Zgodę możesz wycofać w dowolnym momencie.
                            </span>
                          </label>
                          {errors.marketingComms && (
                            <div className="error-message">{errors.marketingComms.message}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap mb-3">
                        <div className="form-group">
                          <input {...register('externalMarketingComms')} type="checkbox" id="externalMarketingComms" />
                          <label htmlFor="externalMarketingComms">
                            <span>
                              Chcę otrzymywać od podmiotów współpracujących z mamfuche.pl oferty specjalne oraz inne
                              treści marketingowe dopasowane do mnie. Zgodę możesz wycofać w dowolnym momencie.
                            </span>
                          </label>
                          {errors.externalMarketingComms && (
                            <div className="error-message">{errors.externalMarketingComms.message}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="account-btn spinner-white-bg" disabled={isSubmitting}>
                    {!isSubmitting ? 'Stwórz konto' : <PulseLoader color="white" loading={isSubmitting} size={10} />}
                  </button>
                </form>

                {/* ----------- FORM END ------------- */}

                {/* ----------- GOOGLE/FACEBOOK ------------- */}

                {/* <div className="alternate-signup-box">
                  <h6>albo zarejestruj się za pomocą</h6>
                  <div className="btn-group gap-4">
                    <Link to={'#'} className="eg-btn google-btn d-flex align-items-center">
                      <i className="bx bxl-google" />
                      <span>Goggle</span>
                    </Link>
                    <Link to={'#'} className="eg-btn facebook-btn d-flex align-items-center">
                      <i className="bx bxl-facebook" />
                      Facebook
                    </Link>
                  </div>
                </div>
                <div className="form-poicy-area">
                  <p>
                    Poprzez kliknięcie przycisku "Zarejestruj się za pomocą Google lub Facebook" stworzysz konto na
                    MamFuche.pl, jednocześnie zgadzając się na <Link to={'#'}>Regulamin</Link> oraz{' '}
                    <Link to={'#'}>Politykę Prywatności</Link> platformy MamFuche.pl
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AboutUsCounter /> */}
    </>
  )
}

export default SignUpContractor

import React from 'react'
import { Link } from 'react-router-dom'
import { API_BASE_URL, DYNAMIC_URL_PATHS } from '../../../_constants/urls'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FuchaDefaultImage from '../../../assets/images/fucha/default-card-image.png'
import AccountIcon from '../../../assets/images/icons/account.svg'
import PlusIcon from '../../../assets/images/icons/plus-icon.svg'
import { MAX_NUMBER_OF_BIDDING_CONTRACTORS } from '../../../_constants'

const FuchaCard = ({ fucha, isEndingSoon = false }) => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const maxNumberOfBiddingContractors = fucha?.bid_count === MAX_NUMBER_OF_BIDDING_CONTRACTORS

  const renderTypeOfWorkIcons = () => {
    const MAX_ICON_LIMIT = 3
    let typesOfWorkData = fucha.types_of_work
    let additionalIconCount = 0
    let additionalTypesOfWorkNames = []
    if (fucha.types_of_work.length > MAX_ICON_LIMIT) {
      typesOfWorkData = typesOfWorkData.slice(0, MAX_ICON_LIMIT - 1)
      additionalIconCount = fucha.types_of_work.length - MAX_ICON_LIMIT
      fucha.types_of_work.map((item, index) => {
        if (index >= MAX_ICON_LIMIT - 1) {
          additionalTypesOfWorkNames.push(item.name)
        }
      })
    }

    const icons = typesOfWorkData.map((item, index) => {
      return !item.icon ? null : (
        <div key={index} className="d-flex flex-wrap justify-content-start align-items-center mb-1">
          <div className="type-of-work-emo">
            <img alt="images" src={item.icon} />
          </div>
          <div className="type-of-work-name">
            <span>{item.name}</span>
          </div>
        </div>
      )
    })

    if (additionalIconCount > 0) {
      icons.push(
        <>
          <div key={4} className="z-3 d-flex flex-wrap justify-content-start align-items-center mb-1">
            <OverlayTrigger
              key={`additional-types-of-work-${fucha.id}`}
              placement="bottom"
              overlay={
                <Tooltip id={`additional-types-of-work-${fucha.id}`} place="right-end">
                  {additionalTypesOfWorkNames.map((name) => (
                    <span style={{ textTransform: 'capitalize' }} key={name}>
                      {name}
                      <br></br>{' '}
                    </span>
                  ))}
                </Tooltip>
              }
            >
              <div id={`additional-types-of-work-${fucha.id}`} className="type-of-work-emo">
                <img alt="images" src={PlusIcon} />
              </div>
            </OverlayTrigger>
            <div className="type-of-work-name">
              <span>+ {additionalIconCount + 1} Inne</span>
            </div>
          </div>
        </>
      )
    }

    return icons
  }

  return (
    <>
      <div data-wow-duration="1.5s" data-wow-delay="0.2s" className="eg-card auction-card2 wow fadeInDown">
        <div className="auction-img">
          <Link to={DYNAMIC_URL_PATHS.FuchaDetail(fucha.id)} onClick={scrollTop}>
            <img className="card-image" alt="liveAuctionImage" src={fucha.image ? fucha.image : FuchaDefaultImage} />
          </Link>
          <div className="type-of-work-area d-flex flex-column align-items-start">{renderTypeOfWorkIcons()}</div>
          <div className="auction-timer">
            <div className="countdown" id="timer1">
              <h5>{fucha.county}</h5>
            </div>
            <h6 className="pb-0">{fucha.province}</h6>
          </div>
        </div>
        <div className="auction-content">
          <h4>
            <Link to={DYNAMIC_URL_PATHS.FuchaDetail(fucha.id)} onClick={scrollTop}>
              {fucha.title}
            </Link>
          </h4>
          {!isEndingSoon ? (
            <p>
              Wystawiona:
              <br /> {fucha.timesince}
            </p>
          ) : (
            <p>
              Kończy się za:
              <br /> {fucha.ends_in}
            </p>
          )}

          <div className="author-price-area">
            <div className="author">
              <img alt="authorimage" src={fucha.user_image ? `${fucha.user_image}` : AccountIcon} />
              <span className="name">{fucha.user_name}</span>
            </div>
            <div>
              <h6>WYCENY:</h6>
              {maxNumberOfBiddingContractors ? (
                <OverlayTrigger
                  key={`${fucha.id}-bid-offer`}
                  placement="top"
                  overlay={<Tooltip id={`${fucha.id}-bid-offer`}>Ta fucha osiągnęła limit liczby wycen</Tooltip>}
                >
                  <p id={`${fucha.id}-bid-offer`} style={{ color: '#f9395f' }}>
                    {fucha.bid_count}
                  </p>
                </OverlayTrigger>
              ) : (
                <p style={{ color: '#696969' }}>{fucha.bid_count}</p>
              )}
            </div>
          </div>
          <div className="auction-card-bttm">
            <Link
              to={DYNAMIC_URL_PATHS.FuchaDetail(fucha.id)}
              onClick={scrollTop}
              className="eg-btn btn--primary2 btn--sm"
            >
              Sprawdź Fuche
            </Link>
            <div className="share-area">
              <i className="bi bi-heart" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FuchaCard

import React, { useState, useEffect } from 'react'
import ChatWrapper from './ChatWrapper'
import { useUser } from '../../../context/UserContext'
import { useChatActions } from '../../../_actions'
import PulseLoader from 'react-spinners/PulseLoader'

const Chat = () => {
  const { user } = useUser()
  const [token, setToken] = useState(null)
  const { getChatToken } = useChatActions()

  useEffect(() => {
    getChatToken()
      .then((data) => {
        setToken(data.token)
      })
      .catch((error) => {
        console.error('Error fetching chat token:', error)
      })
  }, [])

  if (!token || !user)
    return (
      <section className="chat-section">
        <div className="chat-container d-flex align-items-center justify-content-center">
          <PulseLoader color="#f9395f" />
        </div>
        <div className="chat-footer"></div>
      </section>
    )

  return <ChatWrapper user={user} token={token} />
}

export default Chat

import React from 'react'
import Tile from '../../common/Tile'

const SkeletonSummaryTiles = () => {
  const skeletonTabs = () => {
    return Array.from({ length: 6 }).map((_, index) => <Tile key={index} col={6} loading={true} />)
  }

  return (
    <div
      className="tab-pane fade show active"
      id="v-pills-dashboard"
      role="tabpanel"
      aria-labelledby="v-pills-dashboard-tab"
    >
      <div className="dashboard-area box--shadow">
        <div className="row g-4">{skeletonTabs()}</div>
      </div>
    </div>
  )
}

export default SkeletonSummaryTiles

// src/components/Alerts.js
import React from 'react'
import { useAlerts } from '../../context/AlertContext'
import Alert from 'react-bootstrap/Alert'

const Alerts = () => {
  const { alerts, removeAlert } = useAlerts()

  const getPrefix = (type) => {
    let prefix
    switch (type) {
      case 'success':
        prefix = 'Super!'
        break
      case 'info':
        prefix = 'Info'
        break
      case 'warning':
        prefix = 'Ostrzeżenie'
        break
      case 'danger':
        prefix = 'Ups...'
        break
      default:
        prefix = 'Info'
    }
    return `${prefix}  `
  }

  return (
    <div aria-live="polite" aria-atomic="true" className="alert-wrapper">
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          className="mb-1 alert-message"
          variant={alert.type}
          onClose={() => removeAlert(alert.id)}
          dismissible
        >
          <p>{getPrefix(alert.type) + alert.message}</p>
        </Alert>
      ))}
    </div>
  )
}

export default Alerts

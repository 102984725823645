import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PasswordUpdateSchema } from './schemas/updatePassword'
import Form from '../../../common/Form'
import Input from '../../../common/Input'
import { useApi } from '../../../../_helpers'
import { useForm, watch, Controller } from 'react-hook-form'
import { useUserActions } from '../../../../_actions/user.actions'
import { useAlerts } from '../../../../context/AlertContext'
import { zodResolver } from '@hookform/resolvers/zod'

const UpdatePasswordForm = ({ user }) => {
  const { api } = useApi()
  const [openEye, setOpenEye] = useState(false)
  const { passwordUpdate } = useUserActions()
  const { addAlert } = useAlerts()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: zodResolver(PasswordUpdateSchema),
  })

  const handlePasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(1)
    } else {
      setOpenEye(false)
    }
  }
  const handleConfirmPasswordEyeIcon = () => {
    if (openEye === false || openEye === 0) {
      setOpenEye(2)
    } else {
      setOpenEye(false)
    }
  }

  const onSubmit = async (data) => {
    try {
      await passwordUpdate(data)
      addAlert('Twoje hasło zostało zaktualizowane', 'success')
    } catch (error) {
      addAlert('Wystąpił błąd podczas aktualizacji konta. Spróbuj ponownie.', 'danger')
    }
    reset()
  }

  return (
    <Form
      buttonLabel="Zmień hasło"
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      className="eg-btn profile-btn"
      isSubmitting={isSubmitting}
    >
      <div className="form-section-title">
        <p>Zmień Hasło</p>
      </div>
      <div className="col-md-12 text-start">
        <div className="form-inner">
          <label>Hasło *</label>
          <input
            {...register('password')}
            type={openEye === 1 ? 'text' : 'password'}
            name="password"
            id="password"
            placeholder="Hasło"
          />
          <i
            className={openEye === 1 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
            onClick={handlePasswordEyeIcon}
            id="togglePassword"
          />
        </div>
        <div className="password-error">
          {errors.password && <div className="error-message">{errors.password.message}</div>}
        </div>
      </div>

      {/* ----------- Confirm Password ----------- */}
      <div className="col-md-12 text-start">
        <div className="form-inner">
          <label>Powtórz Hasło *</label>
          <input
            {...register('confirmPassword')}
            type={openEye === 2 ? 'text' : 'password'}
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Powtórz Hasło"
          />
          <i
            className={openEye === 2 ? 'bi bi-eye-slash bi-eye' : 'bi bi-eye-slash'}
            onClick={handleConfirmPasswordEyeIcon}
            id="toggleConfirmPassword"
          />
        </div>
        <div className="password-error">
          {errors.confirmPassword && <div className="error-message">{errors.confirmPassword.message}</div>}
        </div>
      </div>
    </Form>
  )
}
export default UpdatePasswordForm

UpdatePasswordForm.propsTypes = {
  user: PropTypes.object.isRequired,
}

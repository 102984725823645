import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { URL_PATHS, API_BASE_URL } from '../../../_constants/urls'
import { useFuchaActions } from '../../../_actions'

const iconStyle = {
  filter: 'invert(94%) sepia(6%) saturate(23%) hue-rotate(243deg) brightness(105%) contrast(106%)',
}

SwiperCore.use([Navigation, Autoplay])
function CategoryHome() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [typesOfWork, setTypesOfWork] = useState([])
  const { getCategories } = useFuchaActions()

  useEffect(() => {
    getCategories()
      .then(({ categoriesData, typesOfWorkData }) => {
        typesOfWorkData.sort((a, b) => a.name.localeCompare(b.name))
        categoriesData.sort((a, b) => a.name.localeCompare(b.name))
        setCategories(categoriesData)
        setTypesOfWork(typesOfWorkData)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const categorySlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    roundLengths: true,
    pagination: false,
    navigation: {
      nextEl: '.category-prev2',
      prevEl: '.category-next2',
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      380: {
        slidesPerView: 2,
      },
      540: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  }
  return (
    <>
      <div className="category-section2 pt-120 pb-120" id="category">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <Swiper {...categorySlider} className="swiper category2-slider">
                <div className="swiper-wrapper">
                  {typesOfWork.map((item, index) => {
                    return (
                      <SwiperSlide key={index} className="swiper-slide">
                        <div
                          className="eg-card category-card2 wow fadeInDown"
                          data-wow-duration="1.5s"
                          data-wow-delay="0.2s"
                        >
                          <img alt="categoryImage" src={`${item.image}`} className="category-img" />
                          <div className="content">
                            <Link to={`${URL_PATHS.FUCHA_LISTING}?typ-pracy=${item.name}`} onClick={scrollTop}>
                              <img alt="categoryImage" src={`${item.icon}`} style={iconStyle} />
                            </Link>
                            <h5>
                              <Link to={`${URL_PATHS.FUCHA_LISTING}?typ-pracy=${item.name}`} onClick={scrollTop}>
                                {item.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryHome

const Controllers = ({
  previousStep,
  isFirstStep,
  isLastStep,
  handleFinish,
  formId,
  extraButton,
  extraButtonText,
  lastStepText,
  nextStepText,
  previousStepText,
  onExtraButtonClick,
}) => {
  return (
    <div className="d-flex controllers-btn-container mb-5 mt-4">
      {!isFirstStep && (
        <div className="d-flex controllers-btn-box">
          <button className={'eg-btn btn--primary btn--md'} onClick={previousStep}>
            {previousStepText}
          </button>
        </div>
      )}
      <div className="d-flex controllers-btn-box">
        <button
          className={'eg-btn btn--primary btn--md'}
          form={formId}
          type="submit"
          onClick={isLastStep ? handleFinish : null}
        >
          {isLastStep ? lastStepText : nextStepText}
        </button>
      </div>
      {isLastStep && extraButton && (
        <div className="d-flex controllers-btn-box">
          <button onClick={onExtraButtonClick} className="eg-btn btn--primary btn--md extra-button">
            {extraButtonText}
          </button>
        </div>
      )}
    </div>
  )
}

export default Controllers

import * as React from 'react'
import Badge from '@mui/joy/Badge'
import Avatar from '@mui/joy/Avatar'

export default function AvatarWithStatus(props) {
  const { online = false, ...other } = props
  return (
    <div>
      <Badge
        color={online ? 'success' : 'neutral'}
        variant={online ? 'solid' : 'soft'}
        size="md"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeInset="4px 4px"
      >
        <Avatar size="md" {...other} />
      </Badge>
    </div>
  )
}

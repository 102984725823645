import { z } from 'zod'
import { PASSWORD_VALIDATION } from '../../../_constants'

export const LoginSchema = z.object({
  email: z.string().email('Podaj prawidłowy adres email').max(100, 'Maksymalna liczba znaków to 100'),
  password: z
    .string()
    .min(8, 'Hasło musi zawierać co najmniej 8 znaków')
    .max(25, 'Hasło może zawierać maksymalnie 25 znaków')
    .regex(PASSWORD_VALIDATION, {
      message: 'Hasło musi zawierać co najmniej jedną wielką literę, małą literę i cyfrę',
    }),
})

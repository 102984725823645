import React from 'react'
import { getQueryParam } from '../../../_helpers'
import { Channel, ChannelList, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react'
import CustomChannelPreview from './CustomChannelPreview'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'

// Set dayjs to use the Polish locale
dayjs.locale('pl')

const ChatChannels = ({ user, client }) => {
  const channelId = getQueryParam('chatId')

  const sort = { last_message_at: -1 }
  const options = {
    limit: 40,
  }
  const filters = {
    type: 'messaging',
    members: { $in: [user.id] },
  }

  return (
    <>
      <ChannelList
        filters={filters}
        sort={sort}
        options={options}
        customActiveChannel={channelId}
        Preview={CustomChannelPreview}
      />
      <Channel filters={filters} sort={sort} options={options}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </>
  )
}

export default ChatChannels

import React, { useState } from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import HowItWorkContent from './HowItWorkContent'
import HowItWorkContractorContent from './HowItWorkContractorContent'
import WhyChooseUs from './WhyChooseUs'
import WhyChooseUsContractor from './WhyChooseUsContractor'
import { getQueryParam, setQueryParam, removeQueryParam } from '../../../_helpers'

function HowItWork() {
  const [activeTab, setActiveTab] = useState(getQueryParam('dla-fachowca') ? 'contractor' : 'client')

  return (
    <>
      <Breadcrumb pageName="Jak działa mamfuche.pl?" pageTitle="Dowiedz się jak działa nasza platforma" />
      <div className="d-flex w-100 mt-5 align-center justify-content-center how-works-btn-container">
        <button
          className={`how-works-btn ${activeTab === 'client' ? 'active-tab' : ''}`}
          onClick={() => {
            removeQueryParam('dla-fachowca')
            setActiveTab('client')
          }}
        >
          Informacje dla właściciela fuchy
        </button>
        <button
          className={`how-works-btn ${activeTab === 'contractor' ? 'active-tab' : ''}`}
          onClick={() => {
            setQueryParam('dla-fachowca', '1')
            setActiveTab('contractor')
          }}
        >
          Informacje dla fachowca
        </button>
      </div>
      {activeTab === 'client' && (
        <>
          <HowItWorkContent />
          <WhyChooseUs />
        </>
      )}
      {activeTab === 'contractor' && (
        <>
          <HowItWorkContractorContent />
          <WhyChooseUsContractor />
        </>
      )}
      {/* <AboutUsCounter /> */}
      <div className="free-illustrations-text">
        <a href="https://streamlinehq.com">Free illustrations from Streamline</a>
      </div>
    </>
  )
}

export default HowItWork

const authTokenKey = '__mamfucheT'

export const setLocalMemoryAuthToken = (token) => {
  if (!token) {
    localStorage.removeItem(authTokenKey)
    return
  }
  localStorage.setItem(authTokenKey, JSON.stringify(token))
}

export const getLocalMemoryAuthToken = () => {
  const token = localStorage.getItem(authTokenKey)
  if (!token) {
    return null
  }
  return JSON.parse(token)
}

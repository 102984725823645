import { z } from 'zod'
import { WORK_STATE_VALUES } from '../../../../../_constants'

export const createFuchaSchema = z.object({
  county: z.string().min(1, { message: 'Wybierz region/miasto' }),
  categories: z
    .array(
      z.object({
        label: z.string().min(1, { message: 'ID is required' }),
        value: z.string().min(1, { message: 'Name is required' }),
      })
    )
    .min(1, { message: 'Wybierz przynajmniej jedną kategorię' }),
  typesOfWork: z
    .array(
      z.object({
        label: z.string().min(1, { message: 'ID is required' }),
        value: z.string().min(1, { message: 'Name is required' }),
      })
    )
    .min(1, { message: 'Wybierz przynajmniej jednen zakres prac' }),
  fuchaDescription: z
    .string()
    .min(1, { message: 'To pole jest wymagane' })
    .max(1500, { message: 'Opis może zawierać maksymalnie 1500 znaków' }),
  realizationTime: z.enum(['realizationAsap', 'realizationNotAsap', 'realizationInDates'], {
    message: 'Wybierz jedną z opcji',
  }),
  province: z.string().min(1, { message: 'Wybierz województwo' }),
  sqm: z
    .union([z.string(), z.number()])
    .transform((val) => (typeof val === 'string' ? parseInt(val, 10) : val))
    .refine((val) => !isNaN(val), { message: 'To pole jest wymagane. Wpisz 0 jeżel to pole Cię nie dotyczy.' })
    .refine((val) => val >= 0, { message: 'To pole jest wymagane. Wpisz 0 jeżel to pole Cię nie dotyczy.' })
    .refine((val) => val <= 50000, { message: 'Maksymalna powierzchnia to 50000 m2' }),
  fuchaTitle: z
    .string()
    .min(5, { message: 'Tytuł musi zawierać przynajmniej 5 znaków' })
    .max(50, { message: 'Tytuł może zawierać maksymalnie 50 znaków' }),
  workState: z.enum(WORK_STATE_VALUES, { message: 'Wybierz jedną z opcji' }),
})

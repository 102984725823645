import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import PrivacyPolicyContent from './PrivacyPolicyContent'

function PrivacyPolicy() {
  return (
    <>
      <Breadcrumb pageName="Polityka Prywatności" pageTitle="Polityka Prywatności serwisu mamfuche.pl" />
      <PrivacyPolicyContent />
    </>
  )
}

export default PrivacyPolicy

import React from 'react'
import FuchaDetailsInfo from './FuchaDetailsInfo'

const FuchaDetailsWrap = ({ fucha, setFucha, maybeUser, userLoading, fuchaLoading }) => {
  return (
    <>
      <div className="auction-details-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + '/images/bg/section-bg2.png'}
          className="img-fluid section-bg-bottom"
        />

        <div className="container fucha-detail-container">
          <FuchaDetailsInfo
            fucha={fucha}
            setFucha={setFucha}
            maybeUser={maybeUser}
            userLoading={userLoading}
            fuchaLoading={fuchaLoading}
          />
        </div>
      </div>
    </>
  )
}

export default FuchaDetailsWrap

import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import { DYNAMIC_URL_PATHS } from '../../../../_constants'
import { scrollToTop } from '../../../../_helpers'
import AccountIcon from '../../../../assets/images/icons/account.svg'
import { renderStarRating } from './utils/utils'
import ContractorReviewsSummaryModal from './utils/ContractorReviewsSummaryModal'

const OwnerStatsCard = ({
  bids,
  contractorReviewsSummary,
  fucha,
  handleGetContractorReviewsSummary,
  loadingBids,
  scrollToOffer,
}) => {
  const acceptedBid = fucha.accepted_bid ? fucha.accepted_bid : null
  const [showContractorReviewsSummaryModal, setShowContractorReviewsSummaryModal] = useState(false)

  const getHighestBid = () => {
    return bids.reduce((prev, current) => (prev.bid > current.bid ? prev : current)).bid
  }
  const getLowestBid = () => {
    return bids.reduce((prev, current) => (prev.bid < current.bid ? prev : current)).bid
  }

  useEffect(() => {
    if (acceptedBid) {
      handleGetContractorReviewsSummary(acceptedBid.contractor.id)
    }
  }, [])

  const handleOpenContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(true)
  }

  const handleCloseContractorReviewsSummaryModal = () => {
    setShowContractorReviewsSummaryModal(false)
  }

  const renderSkeleton = () => {
    return (
      <div className="form-title">
        <>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <h5>
            <Skeleton width={100} />
          </h5>
          <button className="eg-btn btn--primary p-2 m-3 join-btn" onClick={scrollToOffer}>
            Sprawdź Wyceny
          </button>
        </>
      </div>
    )
  }

  const renderNoBids = () => {
    return (
      <>
        <h5>Nie ma jeszcze dostępnych wycen</h5>
        <p className="para">
          Spokojnie, fachowcy przeglądają Twoje zgłoszenie. Sprawdź ponownie za chwilę, a na pewno wkrótce pojawi się
          wycena, która Ci odpowiada!
        </p>
      </>
    )
  }

  const renderBidsSummary = () => {
    return (
      <>
        <h5>Liczba wycen: {bids.length}</h5>
        <h5>Najwyższa kwota: {getHighestBid()} zł</h5>
        <h5>Najniższa kwota: {getLowestBid()} zł</h5>
        <button className="eg-btn btn--primary p-2 m-3 join-btn" onClick={scrollToOffer}>
          Sprawdź Wyceny
        </button>
      </>
    )
  }

  const renderAcceptedBid = () => {
    return (
      <>
        <div className="contractor-image-container">
          <div className="contractor-image-box">
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(acceptedBid.contractor.id)} onClick={() => scrollToTop()}>
              <img
                id={`contractor-img-${acceptedBid.id}`}
                src={acceptedBid.contractor.image ? acceptedBid.contractor.image : AccountIcon}
                alt="Admin"
                className="rounded-circle"
                width="50"
              />
            </Link>
          </div>
        </div>

        <div className="contractor-details-container">
          <div className="information-container">
            <div className="contractor-name-container">
              <div className="name-container">
                <p className="name">{acceptedBid.contractor.name}</p>
              </div>
              <div
                style={{ cursor: contractorReviewsSummary.average_score ? 'pointer' : 'auto' }}
                className="review-rate"
                onClick={() => {
                  if (contractorReviewsSummary.average_score) {
                    handleOpenContractorReviewsSummaryModal()
                  }
                }}
              >
                {renderStarRating(contractorReviewsSummary.average_score)}
                <span>
                  ({contractorReviewsSummary.average_score ? contractorReviewsSummary.average_score.toFixed(2) : '0'})
                </span>
              </div>
            </div>
            <div className="icon-container">
              <div className="icon">
                <i className="bi bi-check2-circle"></i>
              </div>
              <p>Zatwierdzona wycena: {acceptedBid.bid} zł</p>
            </div>
            <Link to={DYNAMIC_URL_PATHS.ContractorProfile(acceptedBid.contractor.id)} onClick={() => scrollToTop()}>
              <button className="eg-btn btn--primary p-2 m-3 join-btn">Profil Fachowca</button>
            </Link>
            <OverlayTrigger
              key={`contractor-message-${acceptedBid.id}`}
              placement="top"
              overlay={<Tooltip id={`contractor-message-${acceptedBid.id}`}>Napisz wiadomość do fachowca</Tooltip>}
            >
              <button
                id={`contractor-message-${acceptedBid.id}`}
                className="eg-btn btn--primary p-2 m-3 px-3 join-btn message-acceptedBid"
              >
                <i className="bi bi-chat-left-dots"></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <div className="sidebar-widget-title">
            <h4>{acceptedBid ? 'Udało Ci się znaleźć fachowca!' : 'Podsumowanie Wszystkich Wycen'}</h4>
            <span />
          </div>
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            {loadingBids ? (
              renderSkeleton()
            ) : acceptedBid ? (
              renderAcceptedBid()
            ) : (
              <div className="form-title">{bids.length === 0 ? renderNoBids() : renderBidsSummary()}</div>
            )}
          </div>
        </div>
      </div>
      <ContractorReviewsSummaryModal
        showModal={showContractorReviewsSummaryModal}
        handleClose={handleCloseContractorReviewsSummaryModal}
        contractorReviewsSummary={contractorReviewsSummary}
        acceptedBid={acceptedBid}
        loading={loadingBids}
      />
    </>
  )
}

export default OwnerStatsCard

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useContractorActions } from '../../../_actions'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { DYNAMIC_URL_PATHS } from '../../../_constants'
import { getFormattedDate } from '../../../_helpers'
import Badge from 'react-bootstrap/Badge'
import ReactPaginate from 'react-paginate'
import { getPageCount, getToPageNumber, getFromPageNumber } from '../../../_helpers'

const ContentOfMyFuchy = () => {
  const { getMyFuchy } = useContractorActions()
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('all')
  const [fuchy, setFuchy] = useState([])
  const [page, setPage] = useState(1)
  const [fuchyCount, setFuchyCount] = useState(0)
  const perPage = 15

  useEffect(() => {
    getMyFuchy(page, filter)
      .then((resp) => {
        setFuchy(resp.items)
        setFuchyCount(resp.count)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    getMyFuchy(page, filter)
      .then((resp) => {
        setFuchy(resp.items)
        setFuchyCount(resp.count)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [filter, page])

  const getFuchaStatusBadge = (fucha) => {
    if (fucha === 'wygrana') {
      return <Badge bg="success">Wygrana</Badge>
    } else if (fucha === 'aktywna') {
      return <Badge bg="primary">Aktywna</Badge>
    } else {
      return <Badge bg="secondary">Nieaktywna</Badge>
    }
  }

  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  const handleCategory = (e) => {
    setLoading(true)
    setFilter(e.target.value)
    setPage(1)
  }

  const getSkeletonRows = () => {
    const rows = []
    for (let i = 0; i < 10; i++) {
      rows.push(
        <tr key={i}>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={70} />
          </td>
        </tr>
      )
    }
    return rows
  }
  return (
    <div className="tab-pane fade show" id="v-pills-purchase" role="tabpanel" aria-labelledby="v-pills-purchase-tab">
      <div className="table-title-area">
        <div></div>
        <select id="fucha-category" onChange={handleCategory}>
          <option value={'all'}>Pokaż: Wszystkie</option>
          <option value={'active'}>Pokaż: Aktywne</option>
          <option value={'obtained'}>Pokaż: Wygrane</option>
          <option value={'archived'}>Pokaż: Nieaktywne</option>
        </select>
      </div>

      <div className="table-wrapper">
        {fuchyCount === 0 && !loading ? (
          <p style={{ marginBottom: '0', padding: '20px', fontWeight: 'bold' }} className="para text-center">
            Brak zrealizowanych fuch
          </p>
        ) : (
          <table className="eg-table order-table table mb-0 my-fuchy-table">
            {loading ? (
              <>{getSkeletonRows()}</>
            ) : (
              <>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Fucha</th>
                    <th>Wycena</th>
                    <th>Zakres Prac</th>
                    <th>Lokalizacja</th>
                    <th>Data oferty</th>
                  </tr>
                </thead>
                <tbody>
                  {fuchy.map((fucha) => (
                    <tr key={fucha.id}>
                      <td>{getFuchaStatusBadge(fucha.my_fucha_status)}</td>
                      <td>
                        <Link
                          to={DYNAMIC_URL_PATHS.FuchaDetail(fucha.id)}
                          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                          <b>
                            <u>{fucha.title}</u>
                          </b>
                        </Link>
                      </td>
                      <td>{fucha.bids.length > 0 ? <b>{fucha.bids[0].bid}&nbsp;zł</b> : null}</td>
                      <td className="badge-items">
                        {fucha.types_of_work.map((item) => (
                          <React.Fragment key={item.name}>
                            <Badge bg="warning" text="dark">
                              {item.name}
                            </Badge>
                          </React.Fragment>
                        ))}
                      </td>
                      <td>
                        <b>{fucha.province}</b>
                        <br /> {fucha.county}
                      </td>
                      <td>{fucha.bids.length > 0 ? <b>{getFormattedDate(fucha.bids[0].created_at)}</b> : null}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        )}
      </div>
      <div className="table-pagination">
        <p>
          <b>Liczba Fuch: {fuchyCount}</b> <br />
          {fuchyCount > 0 &&
            `Wyświetlasz od ${getFromPageNumber(page, perPage)} do ${getToPageNumber(page, perPage, fuchyCount)}`}
        </p>
        <nav className="pagination-wrap">
          <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={getPageCount(fuchyCount, perPage)}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={page - 1}
            />
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default ContentOfMyFuchy

import { z } from 'zod'
import { URL_REGEX, FACEBOOK_URL_REGEX, INSTAGRAM_URL_REGEX, TIKTOK_URL_REGEX } from '../../../../_constants'

export const ContractorMyProfile = z.object({
  profileBusinessType: z.enum(['brak', 'działalność jednoosobowa', 'firma']).optional().nullable(),
  profileBioContractor: z.string().max(1500, 'Maksymalnie 1500 znaków').optional().nullable(),
  profileCompanyName: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) => {
        if (val === null || val === undefined || val === '') return true
        return val.length >= 3 && val.length <= 50
      },
      {
        message: 'Minimalna liczba znaków to 3, a maksymalna to 50',
      }
    ),
  facebookURL: z
    .string()
    .regex(FACEBOOK_URL_REGEX, 'Adres URL Facebooka jest nieprawidłowy')
    .or(z.literal(''))
    .nullable(),
  instagramURL: z
    .string()
    .regex(INSTAGRAM_URL_REGEX, 'Adres URL Instagrama jest nieprawidłowy')
    .or(z.literal(''))
    .nullable(),
  tiktokURL: z.string().regex(TIKTOK_URL_REGEX, 'Adres URL TikToka jest nieprawidłowy').or(z.literal('')).nullable(),
  websiteURL: z
    .string()
    .regex(URL_REGEX, 'Adres URL strony internetowej jest nieprawidłowy')
    .or(z.literal(''))
    .nullable(),
})

import React, { createElement } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

const Form = ({
  defaultValues,
  buttonLabel = '',
  children,
  onSubmit,
  handleSubmit,
  register,
  isSubmitting,
  ...rest
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <div className="row">
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? createElement(child.type, {
                    ...{
                      ...child.props,
                      register,
                      key: child.props.name,
                    },
                  })
                : child
            })
          : children}
        <div className="col-12">
          <div className="button-group">
            <button type="submit" className="eg-btn profile-btn spinner-white-bg" disabled={isSubmitting}>
              {!isSubmitting ? buttonLabel : <PulseLoader color="white" loading={isSubmitting} size={10} />}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form

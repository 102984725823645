import React from 'react'
import * as Sentry from '@sentry/react'
import DocumentMeta from 'react-document-meta'
import Footer from './common/Footer'
import HomePage from './page/homepage/HomePage'
import Header from './common/Header'
import BottomNav from './common/BottomNav'
import Contact from './page/contact/Contact'
import PricesPage from './page/pricesPage/PricesPage'
import PrivacyPolicyContent from './page/privacyPolicy/PrivacyPolicyContent.jsx'
import TermsAndConditions from './page/terms/TermsAndConditions'
import ErrorPage from './page/error/ErrorPage'
import SignUpClient from './page/signUp/SignUpClient'
import SignUpContractor from './page/signUp/SingnUpContractor'
import Faq from './page/faq/Faq'
import PrivateRoute from './common/PrivateRoute'
import '../index.css'
import Login from './page/login/Login.jsx'
import passwordReset from './page/passwordReset/passwordReset.jsx'
import PasswordUpdate from './page/passwordReset/passwordUpdate.jsx'
import FuchaDetails from './page/fuchaDetails/FuchaDetails.jsx'
import Dashboard from './page/dashboard/Dashboard'
import Blog from './page/blog/Blog'
import Chat from './page/chat/Chat.jsx'
import BlogDetails from './page/BlogDetails/BlogDetails'
import FuchaListing from './page/fuchaListing/FuchaListing.jsx'
import HowItWork from './page/howItWork/HowItWork'
import About from './page/about/About'
import ContractorProfilePage from './page/contractorProfilePage/ContractorProfilePage'
import Alerts from './common/Alerts'
import CookieBot from 'react-cookiebot'
import { AlertProvider } from '../context/AlertContext'
import { AuthProvider } from '../context/AuthContext'
import { DashboardTabProvider } from '../context/DashboardTabContext.js'
import { NotificationsAndMessagesProvider } from '../context/NotificationsAndMessagesContext.js'
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom'
import { URL_PATHS, DYNAMIC_URL_PATHS } from '../_constants/urls.js'
import { history } from '../_helpers/history.js'

const domainGroupId = '04ba0065-5444-4c69-9999-f4d66747e3af'

if (['production', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT)) {
  Sentry.init({
    dsn: 'https://5b1d11a68389e8627c8e6695a67f9049@o4508233769877504.ingest.de.sentry.io/4508233819422800',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api\.mamfuche\.pl/, /^https:\/\/www\.api\.mamfuche\.pl/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

let meta = {}
if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  meta = {
    meta: {
      name: {
        robots: 'noindex, nofollow',
      },
    },
  }
}

function App() {
  return (
    <DocumentMeta {...meta}>
      <BrowserRouter basename="/">
        <Router history={history}>
          <AlertProvider>
            <AuthProvider>
              <DashboardTabProvider>
                <NotificationsAndMessagesProvider>
                  <Alerts />
                  <Header />
                  <Switch>
                    <Route exact path={URL_PATHS.HOME} component={HomePage} />
                    <Route exact path={URL_PATHS.ABOUT} component={About} />
                    <Route exact path={URL_PATHS.CONTACT} component={Contact} />
                    <Route exact path={URL_PATHS.SIGNUP} component={SignUpClient} />
                    <Route exact path={URL_PATHS.SIGNUP_CONTRACTOR} component={SignUpContractor} />
                    <Route exact path={URL_PATHS.LOGIN} component={Login} />
                    <Route exact path={URL_PATHS.PASSWORD_RESET} component={passwordReset} />
                    <Route exact path={DYNAMIC_URL_PATHS.PasswordUpdate(':token')} component={PasswordUpdate} />

                    <PrivateRoute exact path={URL_PATHS.DASHBOARD} component={Dashboard} />
                    <PrivateRoute
                      exact
                      path={DYNAMIC_URL_PATHS.ContractorProfile(':contractorId')}
                      component={ContractorProfilePage}
                    />
                    <PrivateRoute exact path={URL_PATHS.CHAT} component={Chat} />
                    <Route exact path={URL_PATHS.BLOG} component={Blog} />
                    <Route exact path={URL_PATHS.BLOG_DETAIL} component={BlogDetails} />
                    <Route exact path={URL_PATHS.FUCHA_LISTING} component={FuchaListing} />
                    <Route exact path={DYNAMIC_URL_PATHS.FuchaDetail(':fuchaId')} component={FuchaDetails} />
                    <Route exact path={URL_PATHS.HOW_IT_WORKS} component={HowItWork} />
                    <Route exact path={URL_PATHS.FAQ} component={Faq} />
                    <Route exact path={URL_PATHS.PRICES} component={PricesPage} />
                    <Route exact path={URL_PATHS.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
                    <Route exact path={URL_PATHS.PRIVACY_POLICY} component={PrivacyPolicyContent} />
                    <Route exact path={URL_PATHS.NOT_FOUND} component={ErrorPage} />
                    <Route exact path={'*'} component={ErrorPage} />
                  </Switch>
                  {['production', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT) && (
                    <CookieBot domainGroupId={domainGroupId} />
                  )}
                  <Footer />
                  <BottomNav />
                </NotificationsAndMessagesProvider>
              </DashboardTabProvider>
            </AuthProvider>
          </AlertProvider>
        </Router>
      </BrowserRouter>
    </DocumentMeta>
  )
}

export default App

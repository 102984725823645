import React from 'react'
import { useAuth } from '../../../../context/AuthContext'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../../_constants/urls'

const TryAnotherOneCard = ({ fucha }) => {
  const { auth } = useAuth()

  return (
    <div className="card mb-3" style={{ marginRight: '0' }}>
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Niestety Twoja wycena nie została wybrana</h4>
          <span />
        </div>
        <div
          className="product-details-right wow fadeInDown d-flex flex-column"
          data-wow-duration="1.5s"
          data-wow-delay=".2s"
        >
          <p className="para">
            {`Niestety użytkownik ${fucha.user.first_name} nie wybrał Twojej wyceny.`} Nie martw się, masz jeszcze
            mnóstwo fuch do wyboru!
          </p>
          {auth?.user.is_contractor && (
            <Link
              style={{ width: '165px', padding: '5px 12px' }}
              className="eg-btn btn--primary header-btn"
              to={URL_PATHS.FUCHA_LISTING}
            >
              Przeglądaj Fuchy
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default TryAnotherOneCard

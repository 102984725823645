import React from 'react'
import { useUserActions } from '../../../_actions'
import { scrollToTop, history } from '../../../_helpers'
import { URL_PATHS } from '../../../_constants'

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
  const { logout } = useUserActions()
  const handleClick = () => {
    if (title === 'Wyloguj się') {
      logout()
      return
    } else if (title === 'Wiadomości') {
      history.push(URL_PATHS.CHAT)
      return
    }
    setActiveTab(id)
  }

  return (
    <button
      onClick={() => {
        handleClick()
        scrollToTop()
      }}
      className={`nav-link nav-btn-style mx-auto  mb-20 ${activeTab === id ? 'active' : ''}`}
    >
      {title}
    </button>
  )
}
export default TabNavItem

import { useState } from 'react'
import { useApi } from '../_helpers'
import { API_URLS } from '../_constants'

export const usePaymentActions = () => {
  const api = useApi()
  const [loadingProducts, setLoadingProducts] = useState(true)

  return {
    getProducts,
    getSession,
    getHistory,
    loadingProducts,
  }

  async function getProducts() {
    const response = await api.get(API_URLS.PRODUCTS)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    setLoadingProducts(false)
    return response.data
  }

  async function getSession(payload) {
    const response = await api.post(API_URLS.CHECKOUT_SESSION, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getHistory() {
    const response = await api.get(API_URLS.PAYMENT_HISTORY)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

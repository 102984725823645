import React, { useState, useEffect } from 'react'
import { useUser } from '../../../../context/UserContext'

const AccountStatusTileBody = ({ daysLeft, accountActiveTo }) => {
  const accountStatus = () => {
    const dayLabel = daysLeft === 1 ? 'dzień' : 'dni'

    if (daysLeft <= 0) {
      return (
        <div className="counter-item">
          <h2 className="text-danger">Nieaktywne</h2>
          <p className="para">Przedłuż swoje konto, aby móc dalej korzystać z serwisu!</p>
        </div>
      )
    } else if (daysLeft < 7) {
      return (
        <div className="counter-item">
          <h2 className="text-danger">Aktywne</h2>
          <p className="para">Pamiętaj, przedłuż ważność swojego konta!</p>
        </div>
      )
    } else if (daysLeft < 30) {
      return (
        <div className="counter-item">
          <h2 className="text-warning">Aktywne</h2>
        </div>
      )
    }
    return (
      <div className="counter-item">
        <h2 className="text-success">Aktywne</h2>
        {daysLeft > 0 && (
          <p style={{ marginBottom: '0' }} className="para">
            {' '}
            do {accountActiveTo.toLocaleDateString('pl-PL')} / pozostało {daysLeft} {dayLabel}
          </p>
        )}
      </div>
    )
  }

  return <>{accountStatus()}</>
}

export default AccountStatusTileBody

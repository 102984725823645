import React from 'react'
import { Link } from 'react-router-dom'

function Pagination({ itemsCount, perPage, page, setPage }) {
  const isNextPage = page < Math.ceil(itemsCount / perPage)
  const isPrevPage = page > 1
  const nextPage = page + 1
  const prevPage = page - 1
  const numberOfPages = Math.ceil(itemsCount / perPage)

  const pageHandler = (e, goToPage) => {
    e.preventDefault()
    setPage(goToPage)
    window.scrollTo({ top: 100, behavior: 'smooth' })
  }

  return (
    <>
      <div className="row">
        <nav className="pagination-wrap">
          <ul className="pagination d-flex justify-content-center gap-md-3 gap-2">
            {isPrevPage && (
              <li className="page-item">
                <Link
                  className="page-link"
                  to={'#'}
                  tabIndex={-1}
                  onClick={(e) => {
                    pageHandler(e, prevPage)
                  }}
                >
                  Prev
                </Link>
              </li>
            )}

            {page > 1 && (
              <li className="page-item">
                <Link
                  className="page-link"
                  to={'#'}
                  onClick={(e) => {
                    pageHandler(e, prevPage)
                  }}
                >
                  {prevPage}
                </Link>
              </li>
            )}
            <li className="page-item active" aria-current="page">
              <Link
                className="page-link"
                to={'#'}
                onClick={(e) => {
                  pageHandler(e, page)
                }}
              >
                {page}
              </Link>
            </li>
            {isNextPage && (
              <>
                <li className="page-item">
                  <Link
                    className="page-link"
                    to={'#'}
                    onClick={(e) => {
                      pageHandler(e, nextPage)
                    }}
                  >
                    {nextPage}
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    className="page-link"
                    to={'#'}
                    onClick={(e) => {
                      pageHandler(e, nextPage)
                    }}
                  >
                    Next
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Pagination

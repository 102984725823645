import { z } from 'zod'
import { LETTERS_ONLY, PHONE_NUMBER_9_DIGITS } from '../../../../../_constants'

const customErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === 'undefined') {
      return { message: 'To pole jest wymagane' }
    }
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)

export const PersonalInfoSchema = z.object({
  firstName: z
    .string()
    .min(1, 'To pole jest wymagane')
    .max(20, 'Maksymalna liczba znaków to 20')
    .regex(LETTERS_ONLY, { message: 'Tylko litery są dozwolone' }),
  lastName: z
    .string()
    .min(1, 'To pole jest wymagane')
    .max(20, 'Maksymalna liczba znaków to 20')
    .regex(LETTERS_ONLY, { message: 'Tylko litery są dozwolone' }),
  phoneNumber: z
    .string()
    .min(1, 'To pole jest wymagane')
    .regex(PHONE_NUMBER_9_DIGITS, { message: 'Numer telefonu powinien mieć format: 100200300' }),
})

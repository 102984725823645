import React, { createContext, useContext, useState, useEffect } from 'react'

const AlertContext = createContext()

export const useAlerts = () => useContext(AlertContext)

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([])

  const addAlert = (message, type = 'info', timeout = 5000) => {
    const id = new Date().getTime()
    setAlerts((prevAlerts) => [...prevAlerts, { id, message, type }])

    if (timeout !== null) {
      setTimeout(() => {
        removeAlert(id)
      }, timeout)
    }
  }

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id))
  }

  return <AlertContext.Provider value={{ alerts, addAlert, removeAlert }}>{children}</AlertContext.Provider>
}

import { z } from 'zod'
import { CONTACT_SUBJECTS } from './constants'

export const ContactSchema = z.object({
  email: z.string().email('Podaj prawidłowy adres email').max(100, 'Maksymalna liczba znaków to 100'),
  name: z.string().min(3, 'Imię musi zawierać minimalnie 3 znaki').max(100, 'Imię może zawierać maksymalnie 50 znaków'),
  phone: z
    .string()
    .min(6, 'Numer telefonu musi zawierać minimalnie 6 znaków')
    .max(12, 'Numer telefonu może zawierać maksymalnie 20 znaków'),
  message: z
    .string()
    .min(10, 'Wiadomość musi zawierać minimalnie 10 znaków')
    .max(2000, 'Wiadomość może zawierać maksymalnie 2000 znaków'),
  subject: z.enum(CONTACT_SUBJECTS),
})

import React from 'react'

const CongratsCard = ({ fucha }) => {
  return (
    <div className="card mb-3" style={{ marginRight: '0' }}>
      <div className="card-body">
        <div className="sidebar-widget-title">
          <h4>Twoja wycena została wybrana! Gratulujemy!</h4>
          <span />
        </div>
        <div
          className="product-details-right wow fadeInDown d-flex flex-column"
          data-wow-duration="1.5s"
          data-wow-delay=".2s"
        >
          <p className="para m-2">
            {`Użytkownik ${fucha.user.first_name} wybrał Twoją wycenę!`} Poniżej znajdziesz dane tego użytkownika, więc
            możesz się z nim skontaktować w celu omówienia dalszych szczegółów.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CongratsCard

import { useState } from 'react'
import axios from 'axios'
import { API_URLS } from '../_constants/urls'
import { API_BASE_URL } from '../_constants'
import { useAuth } from '../context/AuthContext'

import { getLocalMemoryAuthToken } from './token'

export const useApi = () => {
  // const alertActions = useAlertActions();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { auth, setAuth } = useAuth()

  // Initialize Axios instance
  const api = axios.create({
    baseURL: API_BASE_URL,
  })

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!auth?.refresh || !auth?.access) {
        return Promise.reject(error)
      }

      const originalRequest = error.config
      const { response } = error
      const refreshToken = auth.refresh

      if (response.status === 401 && response.data.code === 'token_not_valid') {
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post(`${API_BASE_URL}${API_URLS.AUTH_TOKEN_REFRESH}`, {
              refresh: refreshToken,
            })
            const newToken = refreshResponse.data
            setAuth({ ...auth, access: newToken.access, refresh: newToken.refresh })
            const data =
              typeof originalRequest.data === 'string' ? JSON.parse(originalRequest.data) : originalRequest.data
            return axios({
              ...originalRequest,
              data,
              headers: { Authorization: `Bearer ${newToken.access}` },
            })
          } catch (error) {
            const msg = 'Failed to refresh token'
            setAuth(null)
            return Promise.reject(msg)
          }
        } else {
          const msg = 'Missing access token'
          setAuth(null)
          return Promise.reject(new Error(msg))
        }
      } else if (response.status === 401) {
        // logout()
        return Promise.reject(error)
      } else {
        // TODO: Handle error message
        return Promise.reject(error)
      }
    }
  )

  // Request interceptor to set auth header
  api.interceptors.request.use(
    (config) => {
      const token = auth?.access
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  // Wrap Axios methods to include loading and error states
  const makeRequest =
    (method) =>
    async (url, data, config = {}) => {
      setLoading(true)
      setError(null)
      try {
        const response = await api[method](url, data, config)
        if (response.config._retry) {
          setAuth(null)
        }
        setLoading(false)
        return { data: response.data, statusCode: response.status }
      } catch (err) {
        setLoading(false)
        setError(err)
        throw err // Re-throw the error so it can be handled by the caller if needed
      }
    }

  return {
    get: makeRequest('get'),
    post: makeRequest('post'),
    put: makeRequest('put'),
    delete: makeRequest('delete'),
    api: api,
    loading,
    error,
  }
}

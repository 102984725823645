import React, { useState, useEffect } from 'react'
import { getFormattedPrice, getFormattedDate } from '../../../../_helpers'
import { usePaymentActions } from '../../../../_actions'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PaymentHistoryTileBody = () => {
  const [paymentHistory, setPaymentHistory] = useState()
  const [loading, setLoading] = useState(true)
  const { getHistory } = usePaymentActions()

  useEffect(() => {
    getHistory()
      .then((resp) => {
        setPaymentHistory(resp)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  function getSkeletonRows() {
    const rows = []
    for (let i = 0; i < 3; i++) {
      rows.push(
        <tr key={i}>
          <td>
            <Skeleton width={70} />
          </td>
          <td>
            <Skeleton width={100} />
          </td>
          <td>
            <Skeleton width={200} />
          </td>
        </tr>
      )
    }
    return rows
  }

  return (
    <>
      <div className="table-wrapper">
        <SkeletonTheme borderRadius="0.5rem" duration={4}>
          {loading ? (
            <table className="eg-table order-table table mb-0">
              <thead>
                <tr>
                  <th>Kwota</th>
                  <th>Produkt</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>{getSkeletonRows()}</tbody>
            </table>
          ) : paymentHistory && paymentHistory.length > 0 ? (
            <table className="eg-table order-table table mb-0">
              <thead>
                <tr>
                  <th>Kwota</th>
                  <th>Produkt</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((item) => (
                  <tr key={item.id}>
                    <td>{getFormattedPrice(item.amount)}</td>
                    <td>{item.product.name}</td>
                    <td>{getFormattedDate(item.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="para">Brak historii płatności</p>
          )}
        </SkeletonTheme>
      </div>
    </>
  )
}

export default PaymentHistoryTileBody

export const CONTACT_SUBJECTS = [
  'Ogólne pytanie / zapytanie',
  'Zapytanie o możliwość współpracy',
  'Zapytanie o pracę',
  'Zapytanie o produkt',
  'Zapytanie o płatność',
  'Mam problem techniczny',
  'Opinia',
  'Inne',
]

import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import CategoryHome2 from './CategoryHome'
import HeroBanner from './HeroBanner'
import LatestFuchyArea from './LatestFuchyArea'
import RecentNewsArea from './RecentNewsArea'
import SopnsorArea from './SopnsorArea'
import TestimonialSlider2 from '../../common/TestimonialSlider'
import EndingSoon from './EndingSoon'

const HomePage = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Janina K.',
      text: 'Korzystałam z usług tej platformy już kilka razy i zawsze byłam zadowolona. Fachowcy są rzetelni i profesjonalni. Polecam wszystkim!',
      image: '/images/bg/testi1.png',
    },
    {
      id: 2,
      name: 'Michał P.',
      text: 'Szybko znalazłem fachowca do naprawy hydrauliki. Usługa wykonana sprawnie i solidnie. Na pewno skorzystam ponownie.',
      image: '/images/bg/testi2.png',
    },
    {
      id: 3,
      name: 'Ewa L.',
      text: 'Świetna strona! Znalezienie kogoś do remontu kuchni zajęło mi tylko kilka minut. Wszystko poszło gładko i bez problemów.',
      image: '/images/bg/testi3.png',
    },
  ]
  return (
    <>
      <HeroBanner />
      <CategoryHome2 />
      <LatestFuchyArea />
      <TestimonialSlider2
        testimonials={testimonials}
        header="Opinie Naszych Użytkowników"
        text="Opinie Klientów są dla nas niezwykle ważne. Dzięki nim możemy stale podnosić jakość naszych usług i
              dostarczać najlepsze rozwiązania. Oto niektóre z nich:"
      />
      <EndingSoon />
      {/* <SopnsorArea /> */}
      {/* <RecentNewsArea /> */}
      {/* <AboutUsCounter /> */}
    </>
  )
}

export default HomePage

import { createContext, useContext, useState, useEffect } from 'react'
import { getLocalMemoryAuthToken, setLocalMemoryAuthToken } from '../_helpers/token'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(getLocalMemoryAuthToken())
  const [isAuth, setIsAuth] = useState(auth ? true : false)

  useEffect(() => {
    if (auth) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
    setLocalMemoryAuthToken(auth)
  }, [auth])

  return <AuthContext.Provider value={{ isAuth, auth, setAuth }}>{children}</AuthContext.Provider>
}

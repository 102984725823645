import { history, useApi } from '../_helpers'
import { URL_PATHS, API_URLS } from '../_constants'
import { useAuth } from '../context/AuthContext'

export const useUserActions = () => {
  const api = useApi()
  const { setAuth } = useAuth()

  return {
    deleteAccount,
    getChatToken,
    getUser,
    login,
    logout,
    passwordReset,
    passwordUpdate,
    phoneVerificationCode,
    register,
    resendVerificationEmail,
    updateAccountDetails,
    updateAddress,
    updateNotificationAndMarketingPreferences,
    verifyEmail,
    verifyPhone,
  }

  async function login({ email, password }) {
    const response = await api.post(API_URLS.AUTH_TOKEN, { email, password })
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    setAuth(response.data)
    return response.data
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    setAuth(null)
    history.push(URL_PATHS.LOGIN)
  }

  async function deleteAccount(payload) {
    const response = await api.post(API_URLS.DELETE_ACCOUNT, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function passwordReset(payload) {
    const response = await api.post(API_URLS.PASSWORD_RESET, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function passwordUpdate(payload) {
    const response = await api.post(API_URLS.PASSWORD_UPDATE, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function register(user) {
    const response = await api.post(API_URLS.SIGNUP, user)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getChatToken() {
    const response = await api.get(API_URLS.CHAT_TOKEN)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getUser() {
    const response = await api.get(API_URLS.CURRENT_USER)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function phoneVerificationCode() {
    const response = await api.get(API_URLS.PHONE_VERIFICATION_CODE)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function resendVerificationEmail(email) {
    const response = await api.post(API_URLS.RESEND_VERIFICATION_EMAIL, { email: email })
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function updateAccountDetails(payload) {
    const response = await api.put(API_URLS.USER_UPDATE_ACCOUNT, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function updateAddress(payload) {
    const response = await api.put(API_URLS.USER_UPDATE_ADDRESS, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function updateNotificationAndMarketingPreferences(payload) {
    const response = await api.post(API_URLS.USER_UPDATE_NOTIFICATION_AND_MARKETING, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function verifyEmail(payload) {
    const response = await api.post(API_URLS.VERIFY_EMAIL, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function verifyPhone(payload) {
    const response = await api.post(API_URLS.VERIFY_PHONE, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

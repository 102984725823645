import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import FaqWrap from './FaqWrap'

function Faq() {
  return (
    <>
      <Breadcrumb pageName="FAQ" pageTitle="FAQ" />
      <FaqWrap />
    </>
  )
}

export default Faq

import { z } from 'zod'
import { PASSWORD_VALIDATION, PHONE_NUMBER_9_DIGITS } from '../../../_constants'

export const SignUpSchema = z
  .object({
    firstName: z.string().min(1, 'To pole jest wymagane').max(20, 'Maksymalna liczba znaków to 20'),
    lastName: z.string().min(1, 'To pole jest wymagane').max(20, 'Maksymalna liczba znaków to 20'),
    email: z.string().email('Podaj prawidłowy adres email').max(100, 'Maksymalna liczba znaków to 100'),
    phoneNumber: z
      .string()
      .min(1, 'To pole jest wymagane')
      .regex(PHONE_NUMBER_9_DIGITS, { message: 'Numer telefonu powinien mieć format: 100200300' }),
    password: z
      .string()
      .min(8, 'Hasło musi zawierać co najmniej 8 znaków')
      .max(25, 'Hasło może zawierać maksymalnie 25 znaków')
      .regex(PASSWORD_VALIDATION, {
        message: 'Hasło musi zawierać co najmniej jedną wielką literę, małą literę i cyfrę',
      }),
    confirmPassword: z
      .string()
      .min(8, 'Hasło musi zawierać co najmniej 8 znaków')
      .max(25, 'Hasło może zawierać maksymalnie 25 znaków'),
    termsAndConditions: z.boolean().refine((val) => val === true, { message: 'To pole jest wymagane' }),
    marketingComms: z.boolean(),
    externalMarketingComms: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Hasła nie pasują',
    path: ['confirmPassword'],
  })

import React, { useState, useRef, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { URL_PATHS } from '../../../_constants/urls'
import { scrollToTop } from '../../../_helpers'
import Skeleton from 'react-loading-skeleton'
import FuchaDetailCard from './fuchaCards/FuchaDetailCard'
import SimilarFuchyCard from './fuchaCards/SimilarFuchyCard'
import FuchaGalleryCard from './fuchaCards/FuchaGalleryCard'
import FuchaSpecificationsCard from './fuchaCards/FuchaSpecificationsCard'
import BidListItemsCard from './fuchaCards/BidListItemsCard'
import PlaceBidCard from './fuchaCards/PlaceBidCard'
import FuchaOwnerInfoCard from './fuchaCards/FuchaOwnerInfoCard'
import OwnerStatsCard from './fuchaCards/OwnerStatsCard'
import CongratsCard from './fuchaCards/CongratsCard'
import TryAnotherOneCard from './fuchaCards/TryAnotherOneCard'
import { useContractorActions } from '../../../_actions'
import { shouldDisplayServerSideError } from '../../../_helpers'
import { useAlerts } from '../../../context/AlertContext'
import { useClientActions } from '../../../_actions'
import {
  FUCHA_STATUS,
  MAX_NUMBER_OF_BIDDING_CONTRACTORS,
  MAX_NUMBER_OF_ACTIVE_BIDS,
  ADD_BID_COUNT_CHANGE,
  REMOVE_BID_COUNT_CHANGE,
} from '../../../_constants/fucha'
import DisabledFuchaCard from './fuchaCards/DisabledFuchaCard'
import MaxNumberBiddingContractorsCard from './fuchaCards/MaxNumberBiddingContractorsCard'
import MaxBidsNumberForContractorCard from './fuchaCards/MaxBidsNumberForContractorCard'

const FuchaDetailsInfo = ({ fucha, setFucha, maybeUser, userLoading, fuchaLoading }) => {
  const offerListRef = useRef(null)
  const { addAlert } = useAlerts()
  const { getContractorReviewsSummary, getContractorActiveBidsCount } = useContractorActions()
  const { createContractorReview } = useClientActions()
  const [contractorReviewsSummary, setContractorReviewsSummary] = useState([])
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [loadingBids, setLoadingBids] = useState(true)
  const [allBids, setAllBids] = useState([])
  const [ratingValues, setRatingValues] = useState({
    contact: 0,
    price: 0,
    quality: 0,
    time: 0,
  })
  const [contractorActiveBidCount, setContractorActiveBidCount] = useState({
    count: 0,
  })
  const disabledFuchaAction = [FUCHA_STATUS.EXPIRED, FUCHA_STATUS.CANCELLED, FUCHA_STATUS.ARCHIVED].includes(
    fucha?.status
  )
  const maxNumberOfBiddingContractors = fucha?.bid_count === MAX_NUMBER_OF_BIDDING_CONTRACTORS
  const maxBidsNumberForContractor = contractorActiveBidCount.count === MAX_NUMBER_OF_ACTIVE_BIDS

  useEffect(() => {
    if (maybeUser?.is_contractor) {
      getContractorActiveBidsCount()
        .then((resp) => {
          setContractorActiveBidCount(resp)
        })
        .catch(() => {
          addAlert('Wystąpił błąd podczas pobierania danych', 'danger')
        })
    }
  }, [maybeUser])

  const updateContractorActiveBidCount = (change) => {
    setContractorActiveBidCount((prev) => ({
      ...prev,
      count: prev.count + change,
    }))
  }

  const checkIfIsActiveContractorBidInAllBids = useMemo(() => {
    return allBids.some((bid) => bid.contractor.id === maybeUser.id && bid.is_deleted === false)
  }, [allBids, maybeUser])

  const scrollToOffer = () => {
    if (offerListRef.current) {
      offerListRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleCloseReviewModal = () => {
    setShowReviewModal(false)
  }

  const handleGetContractorReviewsSummary = (contractorId) => {
    getContractorReviewsSummary(contractorId)
      .then((data) => {
        setContractorReviewsSummary(data)
        setLoadingBids(false)
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.display, 'danger')
        } else {
          addAlert('Wystąpił błąd podczas pobierania danych o recenzjach', 'danger')
        }
        setLoadingBids(false)
      })
  }

  const handleReviewRating = (type, rate) => {
    switch (type) {
      case 'time':
        setRatingValues((prev) => ({ ...prev, time: rate }))
        break
      case 'quality':
        setRatingValues((prev) => ({ ...prev, quality: rate }))
        break
      case 'contact':
        setRatingValues((prev) => ({ ...prev, contact: rate }))
        break
      case 'price':
        setRatingValues((prev) => ({ ...prev, price: rate }))
        break
      default:
        break
    }
  }

  const handleAddReviewForContractor = () => {
    const payload = {
      contractor_id: fucha.accepted_bid.contractor.id,
      fucha_id: fucha.id,
      completion_time: ratingValues.time,
      quality: ratingValues.quality,
      contact: ratingValues.contact,
      price: ratingValues.price,
    }

    createContractorReview(payload)
      .then(() => {
        addAlert('Recenzja została wysłana pomyślnie!', 'success')
        fucha.has_review = true
        return getContractorReviewsSummary(fucha.accepted_bid.contractor.id)
      })
      .then((updatedData) => {
        setContractorReviewsSummary(updatedData)
        setFucha((prevFucha) => ({
          ...prevFucha,
          accepted_bid: {
            ...prevFucha.accepted_bid,
            contractor: {
              ...prevFucha.accepted_bid.contractor,
              average_score: updatedData.average_score,
            },
          },
        }))
        setAllBids((prevBids) =>
          prevBids.map((bid) => {
            if (bid.contractor.id === fucha.accepted_bid.contractor.id) {
              return {
                ...bid,
                contractor: {
                  ...bid.contractor,
                  average_score: updatedData.average_score,
                },
              }
            }
            return bid
          })
        )
      })
      .catch((err) => {
        if (shouldDisplayServerSideError(err)) {
          addAlert(err.response.data.detail, 'danger', null)
        } else {
          addAlert('Nie udało się wysłać recenzji!', 'danger')
        }
      })
      .finally(() => {
        handleCloseReviewModal()
      })
  }

  const renderTextSkeletonCard = () => {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <h4>
            <Skeleton width={250} />
          </h4>

          <div className="sidebar-widget-title">
            <span />
          </div>
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <div className="bid-form">
              <div className="form-title">
                <Skeleton width={200} />
                <br></br>
                <Skeleton width={450} />
                <Skeleton width={450} />
                <Skeleton width={450} />
                <button className="eg-btn m-3 p-2">
                  <Skeleton width={120} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderRegisterAccountCard = () => {
    return (
      <div className="d-flex align-items-stretch">
        <div className="card mb-3">
          <div className="card-body">
            <div className="sidebar-widget-title">
              <h4>Chcesz złożyć wycenę?</h4>
              <span />
            </div>
            <div className="details-container">
              <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title no-signed-view-title">
                  <h5>Załóż konto i wyślij swoją wycenę!</h5>
                  <p className="para">
                    Na <span className="logo-paragraph ">mamfuche.pl</span> nie mamy żadych ukrytych kosztów!
                    Zarejestruj się i zacznij swoją pierwszą Fuchę!
                  </p>
                </div>
                <div className="eg-btn btn--primary p-2 join-btn">
                  <Link to={URL_PATHS.SIGNUP_CONTRACTOR} onClick={() => scrollToTop()}>
                    Dołącz jako fachowiec
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderRegisterClientAccountCard = () => {
    return (
      <div className="d-flex align-items-stretch">
        <div className="card mb-3">
          <div className="card-body">
            <div className="sidebar-widget-title">
              <h4>A może masz fuchę do zlecenia?</h4>
              <span />
            </div>
            <div className="details-container">
              <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title no-signed-view-title">
                  <h5>Chcesz wystawić fuchę?</h5>
                  <p className="para">
                    Na <span className="logo-paragraph ">mamfuche.pl</span> znajdziesz sprawdzonych fachowców!
                    Zarejestruj się i zacznij swój wymarzony remont!
                  </p>
                </div>
                <div className="eg-btn btn--primary p-2 join-btn">
                  <Link to={URL_PATHS.SIGNUP} onClick={() => scrollToTop()}>
                    Wystaw Fuchę
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row">
        {<FuchaGalleryCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />}
        <div className="col-lg-6 col-md-12 d-flex align-items-stretch flex-column">
          <div className="row">
            {userLoading && fuchaLoading ? (
              renderTextSkeletonCard()
            ) : maxBidsNumberForContractor && !checkIfIsActiveContractorBidInAllBids ? (
              <MaxBidsNumberForContractorCard loadingBids={loadingBids} />
            ) : maxNumberOfBiddingContractors && !checkIfIsActiveContractorBidInAllBids ? (
              <MaxNumberBiddingContractorsCard loadingBids={loadingBids} />
            ) : disabledFuchaAction ? (
              <DisabledFuchaCard />
            ) : maybeUser?.is_contractor ? (
              !fucha.is_bid_accepted ? (
                <PlaceBidCard
                  bids={allBids}
                  disabledFuchaAction={disabledFuchaAction}
                  fucha={fucha}
                  loadingBids={loadingBids}
                  onBidAdded={() => updateContractorActiveBidCount(ADD_BID_COUNT_CHANGE)}
                  setBids={setAllBids}
                />
              ) : fucha?.accepted_bid?.contractor?.id === maybeUser.id ? (
                <CongratsCard fucha={fucha} />
              ) : (
                <TryAnotherOneCard fucha={fucha} />
              )
            ) : !maybeUser?.is_contractor && maybeUser?.id === fucha?.user_id ? (
              <OwnerStatsCard
                bids={allBids}
                contractorReviewsSummary={contractorReviewsSummary}
                disabledFuchaAction={disabledFuchaAction}
                fucha={fucha}
                handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
                isOwner={maybeUser.id === fucha.user_id}
                loadingBids={loadingBids}
                scrollToOffer={scrollToOffer}
              />
            ) : (
              renderRegisterAccountCard()
            )}
          </div>
          <div className="row">
            {userLoading && fuchaLoading ? (
              renderTextSkeletonCard()
            ) : !maybeUser ? (
              renderRegisterClientAccountCard()
            ) : (
              <FuchaOwnerInfoCard fucha={fucha} maybeUser={maybeUser} disabledFuchaAction={disabledFuchaAction} />
            )}
          </div>
        </div>
      </div>
      {maybeUser && fucha ? (
        maybeUser.is_contractor || maybeUser.id === fucha.user_id ? (
          <BidListItemsCard
            bids={allBids}
            contractorReviewsSummary={contractorReviewsSummary}
            disabledFuchaAction={disabledFuchaAction}
            fucha={fucha}
            handleAddReviewForContractor={handleAddReviewForContractor}
            handleCloseReviewModal={handleCloseReviewModal}
            handleGetContractorReviewsSummary={handleGetContractorReviewsSummary}
            handleReviewRating={handleReviewRating}
            isOwner={maybeUser.id === fucha.user_id}
            loadingBids={loadingBids}
            offerListRef={offerListRef}
            ratingValues={ratingValues}
            ref={offerListRef}
            setBids={setAllBids}
            setFucha={setFucha}
            setLoadingBids={setLoadingBids}
            setShowReviewModal={setShowReviewModal}
            showReviewModal={showReviewModal}
            onBidRemoved={() => updateContractorActiveBidCount(REMOVE_BID_COUNT_CHANGE)}
          />
        ) : null
      ) : null}
      <div className="row">
        <FuchaSpecificationsCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />
      </div>
      <div className="row">
        <FuchaDetailCard fucha={fucha} fuchaLoading={fuchaLoading} disabledFuchaAction={disabledFuchaAction} />
      </div>
      <div className="row similar-fuchy-container">
        <SimilarFuchyCard fucha={fucha} fuchaLoading={fuchaLoading} />
      </div>
    </>
  )
}

export default FuchaDetailsInfo

import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Row, Col } from 'react-bootstrap'
import { REGIONS } from '../../../_constants'
import { WORK_STATE } from '../../../_constants'

const provinces = Object.keys(REGIONS)

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const workStateSelectOptions = [
  { value: WORK_STATE.RENOVATION, label: 'Remont' },
  { value: WORK_STATE.NEW_BUILD, label: 'Stan deweloperski' },
  { value: WORK_STATE.OTHER, label: 'Inne' },
]

const toSelectOptions = (values) => {
  return values.map((value) => {
    return { value: value, label: value }
  })
}

const FuchaSearch = ({ filters, setFilters, typesOfWorkSelectOptions, categoriesSelectOptions }) => {
  const [countiesSelectOptions, setCountiesSelectOptions] = useState([])
  const animatedComponents = makeAnimated()

  const getProvinceSelectOptions = () => {
    return toSelectOptions(provinces)
  }

  const provinceSelectOptions = getProvinceSelectOptions()

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const handleProvinceChange = (values) => {
    const counties = []

    // Track all available counties
    let countiesValues = []

    values.forEach((item) => {
      counties.push({ label: item.value, options: toSelectOptions(REGIONS[item.value]) })
      countiesValues.push(REGIONS[item.value])
    })
    setCountiesSelectOptions(counties)
    // Remove selected counties if province has changed and set new provinces
    setFilters({
      ...filters,
      provinces: values.map((item) => item.value),
      counties: filters.counties.filter((value) => countiesValues.includes(value)),
    })
  }

  const handleCountyChange = (values) => {
    setFilters({ ...filters, counties: values.map((item) => item.value) })
  }

  const handleCategoryChange = (values) => {
    setFilters({ ...filters, categories: values.map((item) => item.value) })
  }

  const handleTypesOfWorkChange = (values) => {
    setFilters({ ...filters, types_of_work: values.map((item) => item.value) })
  }

  const handleWorkStateChange = (value) => {
    if (value) {
      setFilters({ ...filters, work_state: value.value })
    } else {
      setFilters({ ...filters, work_state: null })
    }
  }

  useEffect(() => {
    // Set initial counties based on selected provinces
    if (filters.provinces.length > 0) {
      const counties = []

      // Track all available counties
      let countiesValues = []

      filters.provinces.forEach((item) => {
        counties.push({ label: item, options: toSelectOptions(REGIONS[item]) })
        countiesValues.push(REGIONS[item])
      })
      setCountiesSelectOptions(counties)
    }
  }, [])

  return (
    <Row className="mb-4">
      <h6 className="text-uppercase">Filtry</h6>
      <hr className="solid"></hr>
      <Col style={{ zIndex: 1005 }} sm={12} md={6} className="filter-select-container">
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          placeholder="Województwo"
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={null}
          isMulti
          options={provinceSelectOptions}
          noOptionsMessage={() => 'Brak opcji'}
          onChange={(selectedOption) => {
            handleProvinceChange(selectedOption)
          }}
          value={filters.provinces.map((item) => {
            return { value: item, label: item }
          })}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f9395f',
              primary: '#f9395f',
            },
          })}
          styles={{
            option: (provided) => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
          }}
        />
      </Col>
      <Col style={{ zIndex: 1004 }} sm={12} md={6} className="filter-select-container">
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          placeholder="Region"
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={null}
          isDisabled={countiesSelectOptions.length === 0}
          value={filters.counties.map((item) => {
            return { value: item, label: item }
          })}
          isMulti
          options={countiesSelectOptions}
          formatGroupLabel={formatGroupLabel}
          onChange={handleCountyChange}
          noOptionsMessage={() => 'Brak opcji'}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f9395f',
              primary: '#f9395f',
            },
          })}
          styles={{
            option: (provided) => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
          }}
        />
      </Col>
      <Col style={{ zIndex: 1003 }} lg={4} md={12} className="filter-select-container">
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          placeholder="Kategorie"
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={null}
          isMulti
          options={categoriesSelectOptions}
          onChange={handleCategoryChange}
          noOptionsMessage={() => 'Brak opcji'}
          value={filters.categories.map((item) => {
            return { value: item, label: item }
          })}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f9395f',
              primary: '#f9395f',
            },
          })}
          styles={{
            option: (provided) => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
          }}
        />
      </Col>
      <Col style={{ zIndex: 1002 }} lg={4} md={12} className="filter-select-container">
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          placeholder="Zakres Prac"
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={null}
          isMulti
          options={typesOfWorkSelectOptions}
          onChange={handleTypesOfWorkChange}
          noOptionsMessage={() => 'Brak opcji'}
          value={filters.types_of_work.map((item) => {
            return { value: item, label: item }
          })}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f9395f',
              primary: '#f9395f',
            },
          })}
          styles={{
            option: (provided) => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
          }}
        />
      </Col>
      <Col style={{ zIndex: 1001 }} lg={4} md={12} className="filter-select-container">
        <Select
          className="filter-select"
          classNamePrefix="filter-select"
          isClearable={true}
          placeholder="Stan"
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={null}
          options={workStateSelectOptions}
          onChange={handleWorkStateChange}
          noOptionsMessage={() => 'Brak opcji'}
          value={filters.work_state && workStateSelectOptions.find((option) => option.value === filters.work_state)}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f9395f',
              primary: '#f9395f',
            },
          })}
          styles={{
            option: (provided) => ({
              ...provided,
              cursor: 'pointer',
              textAlign: 'left',
            }),
            placeholder: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'left',
            }),
          }}
        />
      </Col>
      <hr className="solid mb-4"></hr>
    </Row>
  )
}

export default FuchaSearch

import { STAR_COLORS } from '../../../../../_constants'

export const getStarColor = (score) => {
  if (score > 4.5) return STAR_COLORS.excellent
  if (score > 3.5) return STAR_COLORS.good
  if (score > 2.5) return STAR_COLORS.average
  if (score > 1.5) return STAR_COLORS.belowAverage
  if (score > 0.1) return STAR_COLORS.poor
  return STAR_COLORS.default
}

export const renderStarRating = (averageScore) => {
  return Array.from({ length: 5 }, (_, index) => {
    const starClass =
      index < Math.floor(averageScore) ? 'bi bi-star-fill' : index < averageScore ? 'bi bi-star-half' : 'bi bi-star'

    const starColor = getStarColor(averageScore)

    return <i key={index} className={starClass} style={{ color: starColor }}></i>
  })
}

export const fixedValue = (value, decimals) => {
  return value !== undefined && value !== null ? value.toFixed(decimals) : '0'
}

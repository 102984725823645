import React, { useState } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { useUser } from '../../../context/UserContext'
import { useAuth } from '../../../context/AuthContext'
import Tabs from './Tabs'

const Dashboard = () => {
  const { user, loading } = useUser()
  const { auth } = useAuth()
  const [products] = useState([])

  return (
    <>
      <Breadcrumb
        pageName={
          user ? `Witaj na swoim profilu ${user.first_name}!` : `Witaj na swoim profilu ${auth.user.first_name}!`
        }
        pageTitle="Tutaj możesz w wygodny sposób zarządzać swoim kontem"
      />
      <Tabs user={user} products={products} loadingUser={loading} />
      {/* <AboutUsCounter /> */}
    </>
  )
}

export default Dashboard

import { Route, Redirect } from 'react-router-dom'
import { URL_PATHS } from '../../_constants'
import { UserProvider } from '../../context/UserContext'

import { useAuth } from '../../context/AuthContext'

export default PrivateRoute

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuth } = useAuth()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuth) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: URL_PATHS.LOGIN, state: { from: props.location } }} />
        }

        // authorized
        return (
          <UserProvider>
            <Component {...props} />
          </UserProvider>
        )
      }}
    />
  )
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import FuchaCard from '../../fuchaListing/FuchaCard'
import { useFuchaActions } from '../../../../_actions'
import SkeletonFuchaCard from '../../../common/SkeletonFuchCard'
import { URL_PATHS } from '../../../../_constants/urls'
import notFoundIcon from '../../../../assets/images/icons/not-found.svg'

const SimilarFuchyCard = ({ fucha, fuchaLoading }) => {
  const { fuchaId } = useParams()
  const [fuchy, setFuchy] = useState([])
  const [loading, setLoading] = useState(true)
  const { getSimilarFuchy } = useFuchaActions()
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  const getSkeletonFuchaCards = () => {
    const cards = []
    for (let i = 0; i < 3; i++) {
      cards.push(
        <div key={i} className="col-lg-4 col-md-6 col-sm-12 p-1">
          <SkeletonFuchaCard />
        </div>
      )
    }
    return cards
  }

  useEffect(() => {
    setLoading(true)
  }, [fuchaId])

  useEffect(() => {
    if (loading && !fuchaLoading) {
      getSimilarFuchy(fucha.id).then((data) => {
        setFuchy(data)
        setLoading(false)
      })
    }
  }, [fuchaLoading, loading])

  const upComingSlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: fuchy.length > 2,
    roundLengths: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: 'true',
    },
    navigation: {
      nextEl: '.coming-prev2',
      prevEl: '.coming-next2',
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
        pagination: false,
      },
      480: {
        slidesPerView: 1,
        pagination: false,
      },
      768: {
        slidesPerView: 2,
        pagination: false,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  }

  SwiperCore.use([Navigation, Autoplay])

  return (
    <>
      <div className="col-md-12 mb-3">
        <div className="card testimonial-card similar-fuchy-card">
          <div className="card-body">
            <div className="sidebar-widget-title">
              <h4>Podobne Fuchy</h4>
              <span />
            </div>
            <div className="upcoming-seciton ending-soon">
              <div className="container">
                <div className="row">
                  {loading ? (
                    getSkeletonFuchaCards()
                  ) : fuchy.length > 0 ? (
                    <>
                      <div className="row g-4">
                        <div className="col-xl-6 col-lg-4 d-lg-block w-100">
                          <div
                            style={{ padding: '5px' }}
                            className="slider-bottom d-flex justify-content-end align-items-center mb-4"
                          >
                            <div className="slider-arrows coming-arrow d-flex justify-content-end gap-3">
                              <div
                                className="coming-prev2 swiper-prev-arrow"
                                tabIndex={0}
                                role="button"
                                aria-label="Previous slide"
                              >
                                <i className="bi bi-arrow-left" />
                              </div>
                              <div
                                className="coming-next2 swiper-next-arrow"
                                tabIndex={0}
                                role="button"
                                aria-label="Next slide"
                              >
                                {' '}
                                <i className="bi bi-arrow-right" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Swiper {...upComingSlider} className="swiper upcoming-slider2">
                        <div className="swiper-wrapper">
                          {fuchy.map((item) => (
                            <SwiperSlide key={item.id} className="swiper-slide">
                              <FuchaCard fucha={item} />
                            </SwiperSlide>
                          ))}
                        </div>
                      </Swiper>
                    </>
                  ) : (
                    <div className="no-fuchy-container">
                      <div className="image-container">
                        <img alt="images" src={notFoundIcon} />
                      </div>
                      <h4 className="text-center">Nie udało nam się znaleźć żadnych fuch...</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SimilarFuchyCard

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import makeAnimated from 'react-select/animated'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import Preloader from '../../common/Preloader'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import PageOne from './multistepform/createContractorProfile/PageOne'
import PageTwo from './multistepform/createContractorProfile/PageTwo'
import PageThree from './multistepform/createContractorProfile/PageThree'
import PageFour from './multistepform/createContractorProfile/PageFour'
import PageFive from './multistepform/createContractorProfile/PageFive'
import useMultiStepForm from './multistepform/useMultistepForm'
import MultiForm from './multistepform/MultiForm'
import Controllers from './multistepform/Controllers'
import { useFuchaActions } from '../../../_actions'
import { useContractorActions } from '../../../_actions'
import { CreateContractorProfile } from './multistepform/createContractorProfile/schema'
import { ContractorMyProfile } from './myProfileForm/schema'
import { useApi } from '../../../_helpers'
import { useAlerts } from '../../../context/AlertContext'
import Form from '../../common/Form'
import Input from '../../common/Input'
import { REGIONS, MAX_IMAGE_SIZE_MB } from '../../../_constants'
import { API_BASE_URL, API_URLS, DYNAMIC_URL_PATHS, DYNAMIC_API_URLS } from '../../../_constants'
import { MAX_GALLERY_IMAGES } from '../../../_constants'
import { scrollToTop } from '../../../_helpers'

// Import React FilePond
import pl_PL from 'filepond/locale/pl-pl.js'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { useUser } from '../../../context/UserContext'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond/dist/filepond.min.css'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const ContentOfMyProfile = ({
  showCreateProfileModal,
  handleShowCreateProfileModal,
  handleCloseCreateProfileModal,
  user,
}) => {
  const { refreshUser, setUser } = useUser()
  const { api } = useApi()
  const { addAlert } = useAlerts()
  const { getCategories } = useFuchaActions()
  const { updateProfile, getGallery } = useContractorActions()
  const [selectedCountiesOptions, setSelectedCountiesOptions] = useState([])
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingGallery, setIsLoadingGallery] = useState(false)
  const [typesOfWorkSelectOptions, setTypesOfWorkSelectOptions] = useState([])
  const provinces = Object.keys(REGIONS)
  const isUserProfile = user.profile ? true : false
  const animatedComponents = makeAnimated()

  const { Step, nextStep, previousStep, goToStep, isFirstStep, isLastStep, currentStep } = useMultiStepForm([
    PageOne,
    PageTwo,
    PageThree,
    PageFour,
    PageFive,
  ])

  const {
    register: registerProfileForm,
    handleSubmit: handleSubmitProfileForm,
    control: controlProfileForm,
    formState: { errors: errorsProfileForm, isSubmitting },
    watch: watchProfileForm,
    getValues: getValuesProfileForm,
    setValue: setValueProfileForm,
    reset: resetProfileForm,
  } = useForm({
    resolver: zodResolver(ContractorMyProfile),
    defaultValues: {
      profileCompanyName: user.profile ? user.profile.company_name : '',
      profileMultiProvince: user.profile
        ? user.profile.work_area.provinces.map((province) => ({ value: province, label: province }))
        : [],
      profileMultiCounty: user.profile
        ? user.profile.work_area.counties.map((county) => ({ value: county, label: county }))
        : [],
      profileBusinessType: user.profile ? user.profile.company_type : [],
      profileTypesOfWork: user.profile
        ? user.profile.types_of_work.map((type) => ({ value: type.id, label: type.name }))
        : [],
      profileBioContractor: user.profile ? user.profile.description : '',
      facebookURL: user.profile ? user.profile.facebook_url : '',
      instagramURL: user.profile ? user.profile.instagram_url : '',
      tiktokURL: user.profile ? user.profile.tiktok_url : '',
      websiteURL: user.profile ? user.profile.website_url : '',
    },
  })

  const {
    register: registerMultiform,
    handleSubmit: handleSubmitMultiform,
    control: controlMultiform,
    formState: { errors: errorsMultiform },
    watch: watchMultiform,
    getValues: getValuesMultiform,
    setValue: setValueMultiform,
    reset: resetMultiform,
  } = useForm({
    resolver: zodResolver(CreateContractorProfile),
    defaultValues: {
      companyName: '',
      typesOfWork: [],
      multiProvince: [],
      multiCounty: [],
      businessType: 'brak',
      bioContractor: '',
    },
  })

  useEffect(() => {
    if (isUserProfile && user.profile.work_area?.provinces) {
      const selectedProvinces = user.profile.work_area.provinces.map((province) => ({
        value: province,
        label: province,
      }))
      handleChangeProvince(selectedProvinces)
    }
    getCategories()
      .then(({ categoriesData, typesOfWorkData }) => {
        setTypesOfWorkSelectOptions(
          typesOfWorkData.map((value) => {
            return { value: value.id, label: value.name }
          })
        )
      })
      .catch((error) => {
        addAlert('Wystąpił błąd podczas pobierania kategorii, spróbuj ponownie później.', 'danger', null)
      })
    getGallery(user.id)
      .then((resp) => {
        const initUserGallery = resp.map((item) => {
          return {
            source: `${item.url}`,
            options: {
              type: 'local',
              serverId: item.id,
            },
            serverId: item.id,
          }
        })
        setFiles(initUserGallery)
        setIsLoadingGallery(false)
      })
      .catch((err) => {
        addAlert('Nie udało się pobrać zdjęć z serwera.', 'danger')
      })
  }, [])

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  }

  const handleChangeProvince = (values) => {
    const counties = []
    // Track all available counties
    let countiesValues = []

    values.forEach((item) => {
      counties.push({ label: item.value, options: toSelectOptions(REGIONS[item.value]) })
      countiesValues = [...countiesValues, ...REGIONS[item.value]]
    })
    setSelectedCountiesOptions(counties)
    // Remove selected counties if province has changed and set new provinces
    setValueProfileForm(
      'profileMultiCounty',
      watchProfileForm('profileMultiCounty').filter((item) => countiesValues.includes(item.value))
    )
  }

  const toSelectOptions = (values) => {
    return values.map((value) => {
      return { value: value, label: value }
    })
  }

  const getProvinceSelectOptions = () => {
    return toSelectOptions(provinces)
  }

  const provinceSelectOptions = getProvinceSelectOptions()

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const multiProvince = watchMultiform('multiProvince')
  const multiCounty = watchMultiform('multiCounty')
  const typesOfWork = watchMultiform('typesOfWork')
  const profileMultiProvince = watchProfileForm('profileMultiProvince')
  const profileMultiCounty = watchProfileForm('profileMultiCounty')
  const profileTypesOfWork = watchProfileForm('profileTypesOfWork')

  const companyOptions = [
    { value: 'brak', label: 'brak' },
    { value: 'działalność jednoosobowa', label: 'działalność jednoosobowa' },
    { value: 'firma', label: 'firma' },
  ]

  const onSubmitMultiform = (data) => {
    if (currentStep === 3) {
      const payload = {
        company_name: data.companyName,
        company_type: data.businessType,
        work_area: {
          provinces: multiProvince && multiProvince.length > 0 ? multiProvince.map((item) => item.value) : [],
          counties: multiCounty && multiCounty.length > 0 ? multiCounty.map((item) => item.value) : [],
          is_whole_country: data.isWholeCountry,
        },
        types_of_work:
          typesOfWork && typesOfWork.length > 0
            ? typesOfWork.map((item) => ({ id: item.value, name: item.label }))
            : [],
        description: data.bioContractor,
      }
      updateProfile(payload)
        .then((res) => {
          addAlert('Twój profil został zaktualizowany', 'success')
        })
        .catch((err) => {
          addAlert('Nie udało się zaktualizować profilu!', 'danger', null)
        })
    }
    nextStep()
  }

  const onSubmitProfileForm = async (data) => {
    const payload = {
      company_name: data.profileCompanyName,
      company_type: data.profileBusinessType,
      work_area: {
        provinces:
          profileMultiProvince && profileMultiProvince.length > 0 ? profileMultiProvince.map((item) => item.value) : [],
        counties:
          profileMultiCounty && profileMultiCounty.length > 0 ? profileMultiCounty.map((item) => item.value) : [],
        is_whole_country: data.isWholeCountry,
      },
      types_of_work:
        profileTypesOfWork && profileTypesOfWork.length > 0
          ? profileTypesOfWork.map((item) => ({ id: item.value, name: item.label }))
          : [],
      description: data.profileBioContractor,
      facebook_url: data.facebookURL,
      instagram_url: data.instagramURL,
      tiktok_url: data.tiktokURL,
      website_url: data.websiteURL,
    }

    try {
      const res = await updateProfile(payload)
      setUser(res)
      addAlert('Twój profil został zaktualizowany', 'success')
    } catch (error) {
      addAlert('Nie udało się zaktualizować profilu!', 'danger', null)
    }
  }

  const handleFinish = (e) => {
    resetMultiform()
    refreshUser()
    handleCloseCreateProfileModal()
    setIsLoading(true)
  }

  const renderCreateProfileModal = () => {
    return (
      <Modal
        className="create-profile-container"
        show={showCreateProfileModal}
        onHide={() => handleCloseCreateProfileModal()}
      >
        <div className="create-profile-modal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={process.env.PUBLIC_URL + '/images/bg/client-right.png'} className="client-right-vector" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/client-right.png'} className="client-left-vector" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle1.png'} className="client-circle1" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle2.png'} className="client-circle2" alt="" />
            <img src={process.env.PUBLIC_URL + '/images/bg/clent-circle3.png'} className="client-circle3" alt="" />
            <div className="col-lg-6">
              <div className="create-profile-form-wrapper wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                {/* ----------- CREATE CONTRACTOR PROFILE FORM START ------------- */}
                <MultiForm id="create-profile-form" onSubmit={handleSubmitMultiform(onSubmitMultiform)}>
                  <ProgressBar now={(currentStep / 4) * 100} />
                  {
                    <Step
                      register={registerMultiform}
                      control={controlMultiform}
                      watch={watchMultiform}
                      getValues={getValuesMultiform}
                      errors={errorsMultiform}
                      setValue={setValueMultiform}
                      typesOfWorkSelectOptions={typesOfWorkSelectOptions}
                    />
                  }
                </MultiForm>
                {/* ----------- FORM END ------------- */}

                <Controllers
                  previousStep={previousStep}
                  isFirstStep={isFirstStep}
                  isLastStep={isLastStep}
                  handleFinish={handleFinish}
                  formId="create-profile-form"
                  previousStepText="Wróć"
                  nextStepText="Dalej"
                  lastStepText="Zakończ"
                />
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }

  const renderPartWhenNotProfileYet = () => {
    return (
      <>
        <div className="create-profile">
          <div className="container">
            <div className="row d-flex justify-content-center g-4 align-items-center">
              <div className="col-lg-6 col-md-10">
                <div className="about-img-area">
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-img.png'}
                    className="img-fluid about-img wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                    alt="about-img"
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-vector.png'}
                    className="img-fluid about-vector"
                    alt=""
                  />
                  <img
                    src={process.env.PUBLIC_URL + '/images/bg/about-linear.png'}
                    className="img-fluid about-linear"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="create-profile-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                  <h2 className="mb-4">Stwórz swój profil żeby zbudować zaufanie wśród zlecajacych fuchy!</h2>
                  <p className="para">
                    Solidny i atrakcyjny profil buduje zaufanie klientów. Możesz przedstawić swoje usługi w sposób
                    profesjonalny, zapewniając potencjalnym klientom pewność co do jakości świadczonych usług.
                  </p>
                  <p className="para">
                    Klienci mogą łatwo znaleźć informacje o Twoich usługach, cenach, lokalizacji itp. Ułatwia to
                    podejmowanie decyzji i zwiększa szanse na skorzystanie z Twoich usług!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container" id="create-button">
          <button onClick={() => handleShowCreateProfileModal(true)} className="eg-btn btn--primary m-3">
            Kliknij By Stworzyć Profil
          </button>
        </div>
        {renderCreateProfileModal()}
      </>
    )
  }

  const renderProfileForm = () => {
    if (isLoading) {
      return <Preloader styles="preloader style-2" />
    } else {
      return (
        <>
          <div
            className="tab-pane fade show my-profile-container"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <div className="dashboard-profile">
              <div className="button-group check-profile-btn-container">
                <Link to={DYNAMIC_URL_PATHS.ContractorProfile(user.id)} onClick={() => scrollToTop()}>
                  <OverlayTrigger
                    key="check-profile"
                    placement="top"
                    overlay={
                      <Tooltip id="check-profile">Sprawdź jak wygląda Twój profil dla innych użytkowników</Tooltip>
                    }
                  >
                    <button
                      style={{ width: 'auto' }}
                      className="eg-btn profile-btn spinner-white-bg"
                      id="check-profile"
                    >
                      Sprawdź swój profil
                    </button>
                  </OverlayTrigger>
                </Link>
              </div>
              <div className="form-wrapper">
                {/* ----------- MY PROFILE CONTRACTOR FORM START ------------- */}
                <Form
                  buttonLabel="Aktualizuj"
                  register={registerProfileForm}
                  handleSubmit={handleSubmitProfileForm}
                  onSubmit={onSubmitProfileForm}
                  className="eg-btn profile-btn"
                  isSubmitting={isSubmitting}
                >
                  {/* ----------- COMPANY TYPE ------------- */}
                  <div className="form-section-title">
                    <p>Rodzaj Działalności</p>
                  </div>
                  <div className="form-inner">
                    <Controller
                      control={controlProfileForm}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className="form-inner"
                          classNamePrefix="form-inner"
                          name={name}
                          inputRef={ref}
                          value={companyOptions.find((option) => option.value === value)}
                          options={companyOptions}
                          onChange={(selectedOption) => onChange(selectedOption.value)}
                          placeholder={<div className="select-placeholder-text">Rodzaj Działalności</div>}
                          defaultValue={
                            user.profile.company_type
                              ? {
                                  value: user.profile.company_type,
                                  label: user.profile.company_type,
                                }
                              : ''
                          }
                          isSearchable={false}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              cursor: 'pointer',
                              textAlign: 'left',
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              textAlign: 'left',
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              textAlign: 'left',
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: '0.25rem',
                            cursor: 'pointer',
                            colors: {
                              ...theme.colors,
                              primary: '#f9395f',
                              primary25: '#f9395f5c',
                              primary50: 'transparent',
                              primary75: 'transparent',
                            },
                          })}
                        />
                      )}
                      name={'profileBusinessType'}
                    />
                    {errorsProfileForm && (
                      <div className="error-message">{errorsProfileForm.profileBusinessType?.message}</div>
                    )}
                  </div>

                  {/* ----------- COMPANY NAME ------------- */}
                  <div className="form-section-title">
                    <p>Nazwa Firmy</p>
                  </div>
                  <Input
                    name="profileCompanyName"
                    type="text"
                    placeholder="Nazwa Firmy"
                    error={errorsProfileForm.profileCompanyName?.message}
                  />

                  {/* ----------- TYPE OF WORK ------------- */}
                  <div className="form-section-title">
                    <p>Czym się zajmujesz</p>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="form-inner">
                      <Controller
                        name={'profileTypesOfWork'}
                        control={controlProfileForm}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className="form-inner"
                            classNamePrefix="form-inner"
                            placeholder="Wybierz usługi"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={null}
                            isMulti
                            options={typesOfWorkSelectOptions}
                            onChange={onChange}
                            value={value}
                            noOptionsMessage={() => 'Brak opcji'}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.25rem',
                              colors: {
                                ...theme.colors,
                                primary25: '#f9395f',
                                primary: '#f9395f',
                                height: '54px',
                              },
                            })}
                            styles={{
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer',
                                textAlign: 'left',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* ----------- LOCATION ------------- */}
                  <div className="form-section-title">
                    <p>Teren Na Którym Pracujesz</p>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="form-inner">
                      <Controller
                        name={'profileMultiProvince'}
                        control={controlProfileForm}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            inputRef={ref}
                            className="form-inner"
                            classNamePrefix="form-inner"
                            placeholder="Województwo"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={value}
                            isMulti
                            options={provinceSelectOptions}
                            noOptionsMessage={() => 'Brak opcji'}
                            onChange={(selectedOption) => {
                              onChange(selectedOption)
                              handleChangeProvince(selectedOption)
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.25rem',
                              colors: {
                                ...theme.colors,
                                primary25: '#f9395f',
                                primary: '#f9395f',
                                height: '54px',
                              },
                            })}
                            styles={{
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer',
                                textAlign: 'left',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="form-inner">
                      <Controller
                        name={'profileMultiCounty'}
                        control={controlProfileForm}
                        render={({ field: { onChange, name, ref, value } }) => (
                          <Select
                            inputRef={ref}
                            className="form-inner"
                            classNamePrefix="form-inner"
                            placeholder="Region"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onChange={onChange}
                            value={value}
                            options={selectedCountiesOptions}
                            formatGroupLabel={formatGroupLabel}
                            noOptionsMessage={() => 'Brak opcji'}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.25rem',
                              colors: {
                                ...theme.colors,
                                primary25: '#f9395f',
                                primary: '#f9395f',
                              },
                            })}
                            styles={{
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer',
                                textAlign: 'left',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                              }),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* ----------- DESCRIPTION ------------- */}
                  <div className="form-section-title">
                    <p>Twój opis</p>
                  </div>
                  <div className="bio-container">
                    <textarea
                      {...registerProfileForm('profileBioContractor')}
                      placeholder="Napisz kilka zdań o sobie:"
                      onInput={(e) => {
                        if (e.target.value.length > 1500) {
                          e.target.value = e.target.value.slice(0, 1500)
                        }
                        const form = e.target.closest('form')
                        const counter = form.querySelector('.text-counter:last-child')
                        counter.textContent = `${e.target.value.length}/1500`
                      }}
                    />
                    {errorsProfileForm && (
                      <div className="error-message">{errorsProfileForm.profileBioContractor?.message}</div>
                    )}
                    <div className="text-counter">0/1500</div>
                  </div>
                  {/* ----------- SOCIAL MEDIA ------------- */}
                  <div className="form-section-title">
                    <p>Media Społecznościowe</p>
                  </div>
                  <Input
                    name="facebookURL"
                    containerClassName="text-start"
                    label="Facebook"
                    type="text"
                    placeholder="Link do Profilu Facebook"
                    error={errorsProfileForm.facebookURL?.message}
                  />
                  <Input
                    name="instagramURL"
                    containerClassName="text-start"
                    label="Instagram"
                    type="text"
                    placeholder="Link do Profilu Instagram"
                    error={errorsProfileForm.instagramURL?.message}
                  />
                  <Input
                    name="tiktokURL"
                    containerClassName="text-start"
                    label="TikTok"
                    type="text"
                    placeholder="Link do profilu TikTok"
                    error={errorsProfileForm.tiktokURL?.message}
                  />
                  <Input
                    name="websiteURL"
                    containerClassName="text-start"
                    label="Strona Internetowa"
                    type="text"
                    placeholder="Link do prywatnej strony internetowej"
                    error={errorsProfileForm.websiteURL?.message}
                  />
                </Form>

                {/* ----------- FORM END ------------- */}

                <div className="row upload-container">
                  <div className="form-section-title">
                    <p>Galeria</p>
                  </div>

                  {/* ----------- MY PROFILE GALLERY START ------------- */}
                  {isLoadingGallery ? (
                    <Skeleton height={500} />
                  ) : (
                    <FilePond
                      {...pl_PL}
                      files={files}
                      allowReorder={false}
                      allowFileSizeValidation={true}
                      maxFileSize={`${MAX_IMAGE_SIZE_MB}MB`}
                      labelMaxFileSizeExceeded={`Maksymalny rozmiar pliku to ${MAX_IMAGE_SIZE_MB}`}
                      allowMultiple={true}
                      onupdatefiles={setFiles}
                      acceptedFileTypes={['image/jpeg', 'image/png']}
                      labelIdle={`Ilość zdjęć ${files.length}/${MAX_GALLERY_IMAGES}<br>Przeciągnij lub dodaj zdjęcia <span class="filepond--label-action">TUTAJ</span>`}
                      maxFiles={8}
                      name="file"
                      allowImageEdit={true}
                      storeAsFile={true}
                      onremovefile={(error, fileItem) => {
                        if (fileItem.serverId) {
                          const fileNameParts = fileItem.file.name.split('.')
                          const fileId = fileNameParts.slice(0, -1).join('.')
                          api.delete(DYNAMIC_API_URLS.ContractorGalleryDetail(fileId), {})
                        }
                      }}
                      server={{
                        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                          const formData = new FormData()
                          formData.append(fieldName, file, file.name)

                          // Creating a CancelToken source
                          const sourceToken = axios.CancelToken.source()

                          api
                            .post(API_URLS.CONTRACTOR_GALLERY, formData, {
                              onUploadProgress: (e) => {
                                progress(e.lengthComputable, e.loaded, e.total)
                              },
                              cancelToken: sourceToken.token,
                            })
                            .then((response) => {
                              if (response.status >= 200 && response.status < 300) {
                                const uniqueFileId = response.data.id
                                load(uniqueFileId)
                              } else {
                                error('An error occurred')
                              }
                            })
                            .catch((err) => {
                              if (axios.isCancel(err)) {
                                console.log('Request canceled', err.message)
                              } else {
                                error(err.message || 'An error occurred')
                              }
                            })

                          // Return the abort method so FilePond can cancel the upload if needed
                          return {
                            abort: () => {
                              sourceToken.cancel('Operation canceled by the user.')
                              abort()
                            },
                          }
                        },
                        revert: (uniqueFileId, load, error) => {
                          api
                            .delete(DYNAMIC_API_URLS.ContractorGalleryDetail(uniqueFileId), {})
                            .then((response) => {
                              if (response.status >= 200 && response.status < 300) {
                                load()
                              } else {
                                error('An error occurred')
                              }
                            })
                            .catch((err) => {
                              error(err.message || 'An error occurred')
                            })
                        },
                        load: (source, load, error, progress, abort, headers) => {
                          // Provide a way to cancel the request
                          const sourceToken = axios.CancelToken.source()
                          axios
                            .get(source, {
                              responseType: 'blob',
                              onDownloadProgress: (progressEvent) => {
                                // Optional: handle the progress event here
                              },
                              cancelToken: sourceToken.token,
                            })
                            .then((response) => {
                              load(response.data)
                            })
                            .catch((err) => {
                              error(err.message)
                            })

                          return {
                            abort: () => {
                              sourceToken.cancel('Operation canceled by the user.')
                            },
                          }
                        },
                      }}
                    />
                  )}

                  {/* ----------- MT PROFILE GALLERY END ------------- */}
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  return <>{isUserProfile ? renderProfileForm() : renderPartWhenNotProfileYet()}</>
}

export default ContentOfMyProfile

ContentOfMyProfile.propTypes = {
  showCreateProfileModal: PropTypes.bool.isRequired,
  handleShowCreateProfileModal: PropTypes.func.isRequired,
  handleCloseCreateProfileModal: PropTypes.func.isRequired,
}

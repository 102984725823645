export const getFormattedPrice = (amount, currency = 'zł') => {
  return `${amount / 100}.00 ${currency}`
}

export const getFormattedDate = (date, withTime = true, local = 'pl-PL') => {
  let createdAt = new Date(date)

  let formattedDate = createdAt.toLocaleString('pl-PL')
  if (!withTime) {
    formattedDate = formattedDate.split(',')[0]
  }
  return formattedDate
}

import { useApi } from '../_helpers'
import { API_URLS, DYNAMIC_API_URLS } from '../_constants'

export const useFuchaActions = () => {
  const api = useApi()
  return {
    createUpdateFucha,
    getCategories,
    getClientDetails,
    getFuchaBids,
    getFuchaDetails,
    getFuchaEndingSoon,
    getFuchaImages,
    getFuchaLatest,
    getFuchaListing,
    getSimilarFuchy,
    placeBid,
    publishFucha,
    startChat,
  }

  async function getFuchaBids(fuchId) {
    const response = await api.get(DYNAMIC_API_URLS.FuchaBids(fuchId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getFuchaEndingSoon() {
    const response = await api.get(API_URLS.FUCHA_ENDING_SOON)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getFuchaLatest() {
    const response = await api.get(API_URLS.FUCHA_LATEST)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getFuchaListing(filters, page) {
    const response = await api.post(API_URLS.FUCHA_LISTING, { ...filters, page: page })
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return { fuchaListingData: response.data.items, count: response.data.count }
  }

  async function getFuchaDetails(fuchaId) {
    const response = await api.get(DYNAMIC_API_URLS.FuchaDetail(fuchaId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getFuchaImages(fuchaId) {
    const response = await api.get(DYNAMIC_API_URLS.FuchaImages(fuchaId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getSimilarFuchy(fuchId) {
    const response = await api.get(DYNAMIC_API_URLS.SimilarFuchy(fuchId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getCategories() {
    const response = await api.get(API_URLS.CATEGORIES)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return { categoriesData: response.data.categories, typesOfWorkData: response.data.types_of_work }
  }

  async function placeBid(payload) {
    const response = await api.post(API_URLS.PLACE_BID, payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function createUpdateFucha(payload, fuchaId) {
    const response = await api.put(DYNAMIC_API_URLS.FuchaDetail(fuchaId), payload)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function publishFucha(fuchaId) {
    const response = await api.post(DYNAMIC_API_URLS.FuchaPublish(fuchaId), {})
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function getClientDetails(fuchaId) {
    const response = await api.get(DYNAMIC_API_URLS.GetClientDetails(fuchaId), {})
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }

  async function startChat(fuchaId, contractorId) {
    const response = await api.post(DYNAMIC_API_URLS.StartChat(fuchaId, contractorId), {})
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

import * as React from 'react'
import Box from '@mui/joy/Box'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import CircleIcon from '@mui/icons-material/Circle'
import AccountIcon from '../../../assets/images/icons/account.svg'
import { useUser } from '../../../context/UserContext'
import AvatarWithStatus from './AvatarWithStatus'
import Badge from '@mui/material/Badge'
import { extendTheme } from '@mui/joy/styles'
import { CssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'

const CustomChannelPreview = ({
  channel,
  displayImage,
  displayTitle,
  latestMessagePreview,
  setActiveChannel,
  activeChannel,
}) => {
  const { user } = useUser()
  const otherMember = Object.values(channel.state.members).find((member) => member.user_id !== user.id)
  const isSelected = channel.id === activeChannel?.id
  const latestMessageAt = channel.state.last_message_at
  const timestamp = latestMessageAt ? new Intl.DateTimeFormat('pl', { timeStyle: 'short' }).format(latestMessageAt) : ''
  const firstPartOfTitle = displayTitle.split(' - ')[0]

  const handleClick = () => {
    setActiveChannel?.(channel)
  }

  const theme = extendTheme({
    palette: {
      primary: {
        main: '#f5f5f5',
        light: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: 'Saira',
    },
  })

  return (
    <React.Fragment>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <ListItem>
          <ListItemButton
            onClick={handleClick}
            selected={isSelected}
            color="neutral"
            sx={{ flexDirection: 'column', alignItems: 'initial', gap: 1, padding: 1 }}
          >
            <Stack direction="row" spacing={1.5}>
              <AvatarWithStatus online={true} src={displayImage ? displayImage : AccountIcon} />
              <Box sx={{ flex: 1 }}>
                <Typography level="title-sm">{firstPartOfTitle}</Typography>
                <Typography level="body-sm">{otherMember?.user?.name || 'Unknown'}</Typography>
              </Box>
              <Box sx={{ lineHeight: 1.5, textAlign: 'right', margin: '5px' }}>
                {channel.state.unreadCount > 0 && (
                  <Badge
                    badgeContent={channel.state.unreadCount}
                    sx={{ bgcolor: '#000' }}
                    color="primary"
                    max={99}
                    overlap="circular"
                  ></Badge>
                )}
                <Typography level="body-xs" noWrap sx={{ display: { xs: 'none', md: 'block' } }}>
                  {timestamp}
                </Typography>
              </Box>
            </Stack>
            <Typography
              level="body-sm"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {latestMessagePreview}
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListDivider sx={{ margin: 0 }} />
      </CssVarsProvider>
    </React.Fragment>
  )
}

export default CustomChannelPreview

import { useState } from 'react'
import { useApi } from '../_helpers'
import { API_URLS, DYNAMIC_API_URLS } from '../_constants'

export const useNotificationActions = () => {
  const api = useApi()
  const [loadingProducts, setLoadingProducts] = useState(true)

  return {
    getUnreadNotifications,
    getUnreadNotificationsCount,
    markAllRead,
    markRead,
  }

  async function getUnreadNotifications(page) {
    const response = await api.get(`${API_URLS.NOTIFICATIONS_UNREAD}?page=${page}`)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return { notificationsData: response.data.items, count: response.data.count }
  }
  async function getUnreadNotificationsCount() {
    const response = await api.get(API_URLS.NOTIFICATIONS_UNREAD_COUNT)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
  async function markRead(notificationId) {
    const response = await api.post(DYNAMIC_API_URLS.NotificationMarkRead(notificationId))
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
  async function markAllRead() {
    const response = await api.post(API_URLS.NOTIFICATIONS_MARK_ALL_READ)
    if (response.statusCode !== 200) {
      return Promise.reject(response)
    }
    return response.data
  }
}

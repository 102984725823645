import React from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

function Breadcrumb(props) {
  return (
    <>
      <div className="inner-banner">
        <div className="fake-div"></div>
        <div className="container">
          <h2
            className={`inner-banner-title wow fadeInLeft ${props.titleClassName ? props.titleClassName : ''}`}
            data-wow-duration="1.5s"
            data-wow-delay=".2s"
          >
            {props.pageName}
          </h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                {props.pageTitle}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Breadcrumb

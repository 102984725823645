import { createContext, useContext, useState, useEffect } from 'react'
import { useNotificationActions, useChatActions } from '../_actions'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { API_WEBSOCKET_BASE_URL } from '../_constants'
import { useAuth } from './AuthContext'
import { jwtDecode } from 'jwt-decode'

const RECONNECT_INTERVAL = 3 * 1000 // 3 seconds in milliseconds
const RECONNECT_ATTEMPTS = 10

const NotificationsAndMessagesContext = createContext()

export const useNotificationsAndMessages = () => useContext(NotificationsAndMessagesContext)

export const NotificationsAndMessagesProvider = ({ children }) => {
  //Public API that will echo messages sent to it back to the client
  const { isAuth, auth } = useAuth()

  const [socketUrl, setSocketUrl] = useState(0)
  const [messageHistory, setMessageHistory] = useState([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [unreadChatThreadsCount, setUnreadChatThreadsCount] = useState(0)
  const [loadingNotificationsAndMessages, setLoadingNotification] = useState(true)

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onError: (e) => console.error('Error in websocket', e),
      reconnectAttempts: RECONNECT_ATTEMPTS,
      reconnectInterval: RECONNECT_INTERVAL,
      shouldReconnect: (e) => {
        console.log('Connection Status', connectionStatus)
        console.log('Reconnecting...')
        return true
      },
      onOpen: (e) => {
        console.log('Connected')
      },
    },
    Boolean(socketUrl)
  )
  const { getUnreadNotificationsCount } = useNotificationActions()
  const { getUnreadMessagesCount } = useChatActions()

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage))
      const data = JSON.parse(lastMessage.data)
      if (data.unread_notifications_count !== undefined) {
        setUnreadNotificationCount(data.unread_notifications_count)
      }
      if (data.unread_messages_count !== undefined) {
        setUnreadMessagesCount(data.unread_messages_count)
      }
    }
  }, [lastMessage])

  useEffect(() => {
    if (isAuth) {
      const decodedToken = jwtDecode(auth.access)
      setSocketUrl(`${API_WEBSOCKET_BASE_URL}/ws/comms/${decodedToken.user_id}/`)
      Promise.all([getUnreadNotificationsCount(), getUnreadMessagesCount()])
        .then(([notificationsResp, messagesResp]) => {
          setUnreadNotificationCount(notificationsResp.count)
          setUnreadMessagesCount(messagesResp.total_unread_count)
          setUnreadChatThreadsCount(messagesResp.total_unread_threads_count)
          setLoadingNotification(false)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [isAuth])

  const refreshNotificationCount = async () => {
    getUnreadNotificationsCount()
      .then((resp) => {
        setUnreadNotificationCount(resp.count)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <NotificationsAndMessagesContext.Provider
      value={{
        unreadNotificationCount,
        loadingNotificationsAndMessages,
        refreshNotificationCount,
        unreadMessagesCount,
        setUnreadMessagesCount,
      }}
    >
      {children}
    </NotificationsAndMessagesContext.Provider>
  )
}

import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import ContactWrapper from './ContactWrapper'

function Contact() {
  return (
    <>
      <Breadcrumb pageName="Masz pytanie?" pageTitle="Skontaktuj się z nami!" />
      <ContactWrapper />
      {/* <AboutUsCounter /> */}
      <div className="free-illustrations-text">
        <a href="https://streamlinehq.com">Free illustrations from Streamline</a>
      </div>
    </>
  )
}

export default Contact

import React, { useEffect } from 'react'
import { history } from '../../../_helpers'
import { URL_PATHS } from '../../../_constants'
import { useNotificationsAndMessages } from '../../../context/NotificationsAndMessagesContext'
import { useCreateChatClient, Chat, Streami18n } from 'stream-chat-react'
import ChatChannels from './ChatChannels'
import { CHAT_KEY } from '../../../_constants'
import PulseLoader from 'react-spinners/PulseLoader'
import { plTranslations } from '../../../_locale/chat'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'

// Set dayjs to use the Polish locale
dayjs.locale('pl')

const i18nInstance = new Streami18n({
  translationsForLanguage: plTranslations,
  DateTimeParser: dayjs,
})
const apiKey = CHAT_KEY

const INACTIVITY_TIMEOUT = 2 * 60 * 1000 // 2 minutes in milliseconds

const ChatWrapper = ({ user, token }) => {
  const { setUnreadMessagesCount } = useNotificationsAndMessages()

  const chatUser = {
    id: user.id,
    image: user.profile_image,
    name: user.first_name,
    is_contractor: user.is_contractor,
  }
  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: token,
    userData: chatUser,
  })

  // useEffect(() => {
  //   // Handle user inactivity and disconnect user when inactivity timeout or when leaving the page or change tab
  //   let inactivityTimeout
  //   if (!client) return
  //   const resetInactivityTimeout = () => {
  //     if (inactivityTimeout) {
  //       clearTimeout(inactivityTimeout)
  //     }
  //     inactivityTimeout = setTimeout(() => {
  //       console.log('Disconnecting user from chat due to inactivity...')
  //       client.disconnectUser().then(() => {
  //         history.push(URL_PATHS.DASHBOARD_SUMMARY)
  //       })
  //     }, INACTIVITY_TIMEOUT)
  //   }

  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       console.log('Disconnecting user from chat due to tab change or page leave...')
  //       client.disconnectUser().then(() => {
  //         history.push(URL_PATHS.DASHBOARD_SUMMARY)
  //       })
  //     }
  //   }

  //   document.addEventListener('visibilitychange', handleVisibilityChange)
  //   document.addEventListener('click', resetInactivityTimeout)
  //   document.addEventListener('keydown', resetInactivityTimeout)

  //   resetInactivityTimeout() // Initialize the timeout

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange)
  //     document.removeEventListener('click', resetInactivityTimeout)
  //     document.removeEventListener('keydown', resetInactivityTimeout)
  //     if (inactivityTimeout) {
  //       clearTimeout(inactivityTimeout)
  //     }
  //     if (client) {
  //       console.log('Disconnecting user from chat...')
  //       client.disconnectUser()
  //     }
  //   }
  // }, [client])

  if (!client)
    return (
      <section className="chat-section">
        <div className="chat-container d-flex align-items-center justify-content-center">
          <PulseLoader color="#f9395f" />
        </div>
        <div className="chat-footer"></div>
      </section>
    )

  client.on((event) => {
    if (event.total_unread_count !== undefined) {
      setUnreadMessagesCount(event.total_unread_count)
    }
  })

  return (
    <section className="chat-section">
      <div className="chat-container">
        <Chat client={client} theme="str-chat__theme-custom" i18nInstance={i18nInstance}>
          <ChatChannels client={client} user={user} />
        </Chat>
      </div>
      <div className="chat-footer"></div>
    </section>
  )
}

export default ChatWrapper
